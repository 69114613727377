import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { Chart, ChartConfiguration } from 'chart.js/auto';

@Component({
  selector: 'app-chart-graph',
  templateUrl: './chart-graph.component.html',
  styleUrls: ['./chart-graph.component.scss']
})
/**
 * @description Component to create single or multiple charts with Chart.js lib.
 */
export class ChartGraphComponent implements AfterViewInit {
  @Input() config: ChartConfiguration;
  @ViewChild('chart') chart: ElementRef;

  /**
   * @description Function to execute code only after view is completely initialized.
   */
  public ngAfterViewInit(): void {
    this.generateGraph();
  }

  /**
   * @description Gets chart element context and builds graph.
   */
  private generateGraph(): void {
    const context = this.chart.nativeElement.getContext('2d');

    new Chart(
      context,
      {
        type: this.config.type,
        data: this.config.data,
        options: this.config.options,
        plugins: this.config.plugins
      }
    );
  }
}
