import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { Vehicle, VehicleSpecs, VehicleType } from '../../interfaces';
import { VehicleTypeBody } from '../../interfaces/vehicle-type';

const apiUrl = environment.apiUrl;

@Injectable()
export class VehiculeTypeProvider {

  constructor(private http: HttpClient,
    private _appService: AppService) { }

  /**
   * @param {string} shipperId - Id of shipper
   * @returns get an array of vehicle types of a shipper
   */
  public async getVehiculeTypes(shipperId?: string): Promise<Array<VehicleType>> {
    const shipperOid = shipperId ? shipperId : this._appService.getShipperOid();

    return await this.http.get<Array<VehicleType>>(apiUrl + '/tiposVehiculos?embarcador=' + shipperOid ).toPromise();
  }

  /**
   * @description get array vehicle kinds and defaults
   */
  public async getVehicleKindsWithDefaults(): Promise<Array<string>> {
    const shipperOid = this._appService.getShipperOid();

    return await this.http.get<Array<string>>(`${apiUrl}/tiposVehiculos?defandship=${shipperOid}`)
      .toPromise();
  }

  /**
   * @description get array of all vehicle kinds
   */
  public async getAllVehicleTypes(): Promise<Array<VehicleSpecs>> {

    return await this.http.get<Array<VehicleSpecs>>(`${apiUrl}/tiposVehiculos?all=true`)
      .toPromise();
  }

  /**
   * @description get array vehicle kinds and defaults
   */
  public async getVehicleSpecs(specs: string): Promise<Array<VehicleSpecs>> {

    return await this.http.get<Array<VehicleSpecs>>(`${apiUrl}/tiposVehiculos?specs=${specs}`)
      .toPromise();
  }

  public async getVehiculeTypesActive(carrierId: string): Promise<object> {
    return await this.http.get<object>(`${apiUrl}/lineaTransporte/${carrierId}/vehiculos?activo=true`).toPromise();
  }

  /**
   * @description Create a vehicle type
   * @param {VehicleTypeBody} vehicleType - vehicle type body
   * @returns {promise<Array<VehicleType>>} - vehicle type created
   */
  public async createVehicleType(vehicleType: VehicleTypeBody): Promise<Array<VehicleType>> {
    return await this.http.post<Array<VehicleType>>(apiUrl + '/tiposVehiculos', vehicleType).toPromise();
  }

  /**
   * @description Update a vehicle type data
   * @param {vehicleTypeBody} vehicleType - vehicle type body
   * @param {string} vehicleId - vehicle id
   * @returns {promise<Array<VehicleType>>} - vehicle type updated
   */
  public async updateVehicleType(vehicleType: VehicleTypeBody, vehicleId: string): Promise<Array<VehicleType>> {
    return await this.http.put<Array<VehicleType>>(apiUrl + '/tiposVehiculos/' + vehicleId, vehicleType).toPromise();
  }

  /**
   * @description delete a vehicle type
   * @param {string} vehicleId - vehicle type Id
   * @param {string} shipperId - shipper Id
   * @returns {Promise<Object>} - A vehicle type deleted
   */
  public async deleteVehicleType(vehicleId: string, shipperId?: string): Promise<Object> {
    return await this.http.delete(apiUrl + '/tiposVehiculos/' + vehicleId + '?' + 'embarcador=' + shipperId).toPromise();
  }

  /**
   * @description Get Shipper Vehicle types and Default Vehicle types
   * @returns {Promise<Array<VehicleType>>} Vehicle types.
   */
  public async getVehicleTypesWithDefaults(): Promise<Array<VehicleType>> {
    const shipperOid = this._appService.getShipperOid();
    const path = `${apiUrl}/tiposVehiculos?defandship=${shipperOid}&fullVehicle=true`;

    return await this.http.get<Array<VehicleType>>(path).toPromise();
  }
}
