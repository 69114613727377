import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { AppConstants } from '../../../../constants/app-constants.constants';
import { AppService } from '../../../../app.service';
import { ControlSelectTranslated } from '../../../../interfaces/control-element';
import { DIALOG_REASON_CREATE_PROPERTIES } from './dialog-reason-create.constants';
import { DialogReasonParam, Reason } from '../../../../interfaces/reason';
import { FormRule, ValidationForm } from '../../../../interfaces/form/form-response';
import { FormsService } from '../../../../services/utils/forms.service';
import { LanguageChangeEventService } from '../../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../../constants/language.constants';
import { LanguageTranslateService } from '../../../../services/translate/language-translate.service';
import { REASON_CONSTANTS } from '../../../../pages/catalogs/reason/reason-view/reason-view.constants';
import { ReasonProvider } from '../../../../providers/reason/reason.provider.service';
import { ReasonType } from '../../../../enums/reason-type';
import { Shipper } from '../../../../interfaces';
import { ToastrAlertsService } from '../../../../services/utils/toastr-alerts.service';

/**
 * @description Component for create reason, this contains all necessary to build the reason.
 */
@Component({
  selector: 'app-dialog-reason-create.component',
  styleUrls: [
    './dialog-reason-create.component.scss',
    '../../../../app.component.scss'
  ],
  templateUrl: './dialog-reason-create.component.html'
})
export class DialogReasonCreateComponent implements OnInit, OnDestroy {
  @ViewChild('drcIdentifier', { static: true }) identifierField: ElementRef;
  public defaultValueStatus: string;
  public isAdmin: boolean;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public pageSize: number;
  public pageSizeOptions: Array<number>;
  public reasonList: Array<Reason>;
  public reasonsLabelsTranslated: any;
  public reasonFormGroup: FormGroup;
  public reasonsTypes: Array<ControlSelectTranslated>;
  public shippersList: Array<Shipper>;
  public shipperId: string;
  public shipperName: string;

  /**
   * @description Initializes the variables of the class when it is instantiated.
   * @param {DialogReasonParam} data - Objecto to pass and received the data from client.
   * @param {AppService} appService - Obtains the necessary information from the authenticated tenant in the system.
   * @param {MatDialogRef<DialogReasonCreateComponent>} dialogRef - Reference to a dialog opened via the MatDialog service.
   * @param {FormBuilder} builder - Service to use the reactive forms.
   * @param {FormsService} formsService - Get methods to work validations and other tools for forms.
   * @param {LanguageChangeEventService} languageChangeEventService - Service to work the translated with the system.
   * @param {LanguageTranslateService} languageTranslateService - Service to get the translated files.
   * @param {ReasonProvider} reasonProvider - Service to generate and getting the reason.
   * @param {ToastrAlertsService} toastService - Service to get the methods to work with toast messages.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogReasonParam,
    private appService: AppService,
    private dialogRef: MatDialogRef<DialogReasonCreateComponent>,
    private readonly builder: FormBuilder,
    private formsService: FormsService,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private reasonProvider: ReasonProvider,
    private toastService: ToastrAlertsService
  ) {
    this.defaultValueStatus = AppConstants.EMPTY_STRING;
    this.reasonsTypes = [];
    this.reasonList = [];
    this.setLanguage();
  }

  /**
   * @description Get all data from 'reasonFormGroup' and build the objecto to persist.
   * @returns {Reason} Final object to persist.
   */
  public buildReason(): Reason {
    const { identifier, description, type } = this.reasonFormGroup.value;
    const reason: Reason = {
      creationDate: new Date(),
      description: description?.trim(),
      identifier: identifier?.trim(),
      isActive: true,
      type: type?.trim(),
      shipper: this.shipperId,
      user: this.shipperName
    };

    return reason;
  }

  /**
   * @description Verify if value of 'Description' is valid.
   * @param {AbstractControl} control - The form control to validate.
   * @returns {object} If is valid retuns and object, else return null.
   */
  public customValidatorDescription(control: AbstractControl): object | null {
    if (this.formsService.isControlEmpty(control)) {
      return { required: true };
    }

    return this.throwRuleInvalid(control, REASON_CONSTANTS.KEY_DESCRIPRION);
  }

  /**
   * @description Verify if value of 'Identifier' is valid.
   * @param {AbstractControl} control - The form control to validate.
   * @returns {object} If is valid retuns and object, else return null.
   */
  public customValidatorIdentifier(control: AbstractControl): object | null {
    if (this.formsService.isControlEmpty(control)) {
      return { required: true };
    }

    return this.throwRuleInvalid(control, REASON_CONSTANTS.KEY_IDENTIFIER);
  }

  /**
   * @description Validate if is valid and it doesn't exist in the data source.
   * @param {Reason} reason - Object to validate.
   * @returns {boolean} Result of evaluation.
   */
  public existAndValidConcept(reason: Reason): boolean {
    if (!reason) {
      return true;
    }

    const data = this.data.reasons.find((item: Reason) => {
      return item.identifier === reason.identifier;
    });
    if (data) {
      return true;
    }

    return false;
  }

  /**
   * @description Verify if the field capture in identifier or charge name exists for another
   * additional charge and valid if available or not.
   * @param {string} field - As id for shearch field.
   * @param {string} value - Value of field.
   * @returns {Promise<ValidationForm>} Value of evaluation error.
   */
  public async existReasonByField(field: string, value: string): Promise<ValidationForm> {
    const validation: ValidationForm = { isValid: false };
    try {
      if (!value) {
        return validation;
      }

      const isValidLength: boolean = this.reasonList.length > AppConstants.ZERO;
      const itemExist = this.reasonList.find((reason: Reason) => {
        return reason[field].toLocaleLowerCase() === value?.toLocaleLowerCase();
      });

      if (!isValidLength || !itemExist) {
        const result = await this.reasonProvider.getReasonByIdentifier(value);
        this.reasonList.push(result?.item as Reason);
      }
      const invalid = itemExist ? true : this.reasonList.find((reason: Reason) => {
        return reason[field].toLocaleLowerCase() === value?.toLocaleLowerCase();
      });
      validation.isValid = invalid ? true : false;

      return validation;
    } catch {
      return validation;
    }
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
      .catch(() => {
        this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Gets Reasons Labels from translate JSON files.
   */
  public async getReasonLabels(): Promise<void> {
    this.reasonsLabelsTranslated = await this.languageTranslateService.getLanguageLabels('reasonViewLabels')
      .catch(() => {
        this.toastService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Get the reasons to show in the view.
   * @param {string} shipperId - Tenant or shipper as id to find.
   */
  public async getReasons(shipperId?: string): Promise<void> {
    try {
      const response = (await this.reasonProvider.getReasons(shipperId)).item as unknown as Array<Reason>;
      this.reasonList.push(...response);
    } catch {
      this.toastService.errorAlert(this.reasonsLabelsTranslated.errorGetReason);
    }
  }

  /**
   * @description Get the list of reasons type to show in the view.
   * @returns {Array<ControlSelectTranslated>} List of reasons translated.
   */
  public getReasonsTypes(): Array<ControlSelectTranslated> {
    const reasonTypeList: Array<ControlSelectTranslated> = [];
    reasonTypeList.push({
      label: this.reasonsLabelsTranslated.evidence,
      value: ReasonType.evidence.valueOf()
    },
    {
      label: this.reasonsLabelsTranslated.incidence,
      value: ReasonType.incidence.valueOf()
    },
    {
      label: this.reasonsLabelsTranslated.reject,
      value: ReasonType.reject.valueOf()
    },
    {
      label: this.reasonsLabelsTranslated.postDated,
      value: ReasonType.postDated.valueOf()
    });

    return reasonTypeList;
  }

  /**
   * @description Initialize reason form.
   */
  public initForm(): void {
    this.reasonFormGroup = this.builder.group({
      description: new FormControl(null, [this.customValidatorDescription.bind(this)]),
      identifier: new FormControl(null, [this.customValidatorIdentifier.bind(this)]),
      type: new FormControl(null, [Validators.required])
    });
  }

  /**
   * @description Angular destroy lifecycle.
   */
  public ngOnDestroy(): void {
    this.languageSuscription.unsubscribe();
  }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    this.initForm();
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getReasonLabels();
    this.shipperId = this.data.shipperId;
    this.shipperName = await this.appService.getShipperNameCookie();
    this.reasonsTypes = this.getReasonsTypes();
    await this.getReasons(this.shipperId);
    this.suscribeKeyUpIdentifierInput();
    if (this.data.reasons?.length) {
      this.reasonList.push(...this.data.reasons);
    }
  }

  /**
   * @description Close dialog opened, everithing captured will be lost.
   */
  public onCancel(): void {
    this.dialogRef.close('closed');
  }

  /**
   * @description Get the value of identifier verify if exist in the same shipper.
   */
  public async onLostFocusIdentifier(): Promise<void> {
    const field = 'identifier';
    const { identifier } = this.reasonFormGroup.value;

    if (identifier && identifier.length > DIALOG_REASON_CREATE_PROPERTIES.MIN_LENGHT_IDENTIFIER &&
      identifier.length < DIALOG_REASON_CREATE_PROPERTIES.MAX_LENGHT_IDENTIFIER) {
      const result = await this.existReasonByField(field, identifier);
      this.setErrorByField(field, result.isValid ? { repeatIdentifier: true } : null);
    }
  }

  /**
   * @description Close dialog opened and 'save' the information in memory and after this can be persist.
   */
  public onSave(): void {
    const reason = this.buildReason();
    if (!this.existAndValidConcept(reason)) {
      this.dialogRef.close(reason);

      return;
    }

    this.toastService.warningAlert(this.reasonsLabelsTranslated.reasonDuplicate);
  }

  /**
   * @description Set and check if all items or some element if selected in the control.
   * @param {MatSelect} matSelect - Object from form view.
   */
  public selectAll(matSelect: MatSelect): void {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => {
        return item?.value === this.defaultValueStatus;
      })
      .map((item: MatOption) => {
        return item.selected;
      })[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => {
        return item.select();
      });
    } else {
      matSelect.options.forEach((item: MatOption) => {
        return item.deselect();
      });
    }
  }

  /**
   * @description Set the error for specific field in form 'chargeCreateGroup'.
   * @param {string} fieldName - As id for shearch field.
   * @param {object} error - Value of error to show.
   */
  public setErrorByField(fieldName: string, error: object): void {
    this.reasonFormGroup.get(fieldName)?.setErrors(error);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Optional language key string, default is spanish 'es'.
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Set values or reset the form 'reasonFormGroup'.
   * @param {Reason} reason - Object with values to set in each field in form reasonFormGroup.
   */
  public setValuesForm(reason?: Reason): void {
    this.reasonFormGroup.patchValue({
      description: reason?.description ?? null,
      identifier: reason?.identifier ?? null,
      type: reason?.type ?? []
    });
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getReasonLabels();
        this.reasonsTypes = this.getReasonsTypes();
      },
      () => {
        this.toastService.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Subscribes to keyup event on global Identifier and charge name input and debounces 1000 to trigger action.
   */
  public suscribeKeyUpIdentifierInput(): void {
    fromEvent(this.identifierField?.nativeElement, 'keyup').pipe(
      map((event: InputEvent) => {
        return (event.currentTarget as HTMLInputElement)?.value;
      }),
      debounceTime(DIALOG_REASON_CREATE_PROPERTIES.DEBOUNCE_TIME),
      distinctUntilChanged()
    ).subscribe(() => {
      this.onLostFocusIdentifier();
    });
  }

  /**
   * @description Check if the field value is valid, currently only validates numeric data
   * and add new rules according to the need.
   * @param {AbstractControl} control - The form control to validate.
   * @param {string} controlName - Name of control as id.
   * @returns {object} If is valid retuns and object, else return null.
   */
  public throwRuleInvalid(control: AbstractControl, controlName: string): FormRule | null {
    const ctrlValue: string = control.value.toString().trim();
    const valueLength = ctrlValue.length;
    if (
      controlName.toLowerCase() === REASON_CONSTANTS.KEY_IDENTIFIER &&
      (valueLength < DIALOG_REASON_CREATE_PROPERTIES.MIN_LENGHT_IDENTIFIER ||
        valueLength > DIALOG_REASON_CREATE_PROPERTIES.MAX_LENGHT_IDENTIFIER)
    ) {
      return { required: true };
    }
    if (
      controlName.toLowerCase() === REASON_CONSTANTS.KEY_DESCRIPRION &&
      (valueLength < DIALOG_REASON_CREATE_PROPERTIES.MIN_LENGHT_DESCRIPTION ||
        valueLength > DIALOG_REASON_CREATE_PROPERTIES.MAX_LENGHT_DESCRIPTION)
    ) {
      return { required: true };
    }

    return null;
  }
}
