import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppConstants } from '../../constants/app-constants.constants';
import { AppService } from '../../app.service';
import { ArrayOids,
  ArrayShipmentRequest,
  EvidenceHistoryData,
  EvidenceHistoryInfoData,
  EvidenceToDisplay,
  File,
  FilesToShow,
  OrderData,
  OrdersApi,
  PictureData,
  ShipmentRequest } from './../../interfaces/index';
import { BlobProvider } from '../../providers/evidence/blob-provider.service';
import { DialogFilePreviewComponent } from '../dialog/dialog-file-preview/dialog-file-preview.component';
import { environment } from '../../../environments/environment';
import { EvidenceProvider } from '../../providers/evidence/evidence-provider.service';
import { FileConversorService } from '../../services/file-conversor';
import { FileData, GenericEvidenceLabels } from './../../interfaces/evidence';
import { GenericIncidenceLabels } from './../../interfaces/incidences';
import { IncidenceData } from './../../interfaces/incidence_data';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import { ShipmentProvider } from '../../providers/shipments/shipment-provider.service';
import { ShipmentsService } from '../../providers/shipments/shipments.service';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';


const BASEURI = environment.baseStorageUrl + environment.evidenceContainer;
const DIALOG_WIDTH = '650px';
const EMPTY_STRING = '' ;
const KEY_DISPLAY_INFO = 'displayInfo';
const KEY_EVIDENCE_INFO = 'evidenceInfo';
const KEY_INCIDENCE_INFO = 'incidenceInfo';
const PDF_EXTENSION = '.pdf';
const SLASH = '/';
const URL = environment.baseStorageUrl + environment.mainContainer;
const URL_NOT_IMAGE = '../../../assets/notImage.png';

@Component({
  selector: 'app-mini-carousel',
  templateUrl: './mini-carousel.component.html',
  styleUrls: ['./mini-carousel.component.scss'],
  providers: [MatDialog]
})
export class MiniCarouselComponent implements OnInit, OnDestroy, OnChanges {
  @Input() displayInfo: boolean;
  @Input() downloadService: any; // TODO: Remove input for the download service without the build bug
  @Input() evidenceAPIInfo: Array<EvidenceHistoryData>;
  @Input() genericTags: GenericEvidenceLabels | GenericIncidenceLabels;
  @Input() images: Array<string>;
  @Input() incidencesData: Array<IncidenceData>;
  @Input() isIncidence: boolean;
  @Input() mobilityInfo: Array<EvidenceHistoryInfoData>;
  @Input() mobilityURLs: Array<string>;
  @Input() orderFolio: string;
  @Input() orderOid: string;
  @Input() title: string;

  public actualPosition: number;
  public auxEvidenceFile: Array<object>;
  public miniCarouselTagsTranslated: any;
  public disableOneFileButton: boolean;
  public downloadAvailable: boolean;
  public evidenceImages: Array<EvidenceToDisplay>;
  public evidencesFileAPI: Array<EvidenceToDisplay>;
  public evidenceURI: Array<string>;
  public fileImages: Array<EvidenceToDisplay>;
  public dialogPreviewTitle: string;
  public tooltipDownload: string;
  public languageSuscription: Subscription;
  public languageLabels: any;
  public noInfo: boolean;
  public order: OrderData;
  public pdfEvidences: Array<FilesToShow>;
  public shipment: Array<ShipmentRequest>;
  public shipperOid: string;

  constructor(
    private blobProvider: BlobProvider,
    // private downloadService: DownloadFilesService, // TODO: Remove it when the build problem is fixed
    public dialog: MatDialog,
    private _appService: AppService,
    private evidencesProvider: EvidenceProvider,
    private fileConversorService: FileConversorService,
    private orderProvider: OrderProvider,
    private shipmentProvider: ShipmentsService,
    private toast: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private _shipmentProvider: ShipmentProvider
  ) {
    this.setLanguage();
  }

  /**
   * @description Angular lifecycle for component initialization
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getCarouselLabels();
    this.shipperOid = this._appService.getShipperOid();
    this.disableOneFileButton = false;
    this.actualPosition = 0;
    this.fileImages = [];
    if (!this.images || !this.images.length) {
      this.noInfo = true;
    } else {
      this.noInfo = false;
      await this.getOrderAndShipment();
      await this.fileConversorService.fileConversor(this.images, false).then((res) => {
        this.fileImages = res;
      });
    }
    if (this.downloadService) {
      this.downloadAvailable = true;
    } else {
      this.downloadAvailable = false;
    }
    if (this.genericTags && this.isIncidence) {
      this.dialogPreviewTitle = this.genericTags.detailTitle;
      this.tooltipDownload = this.genericTags.tooltipDownload;
    }
    this.disableOneFileButton = this.disabledDownloadOneFile(this.images);
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy() {
    this.languageSuscription?.unsubscribe();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  /**
   * @description Get Order and shipment data
   */
  public async getOrderAndShipment(): Promise<void> {
    const orderId: ArrayOids = { ordersIds: [ this.orderOid ] };
    const orders: Array<OrdersApi> = await this.orderProvider.getOrdersByOids(orderId);
    this.order = this.convertToOrderData(orders[0]);
    if (this.order) {
      const shipmentsRequest = orders[0].shipment.filter(shipment => shipment.shipmentId.startsWith('S2'));
      const shipmentRequestId = [];
      for (const shipmentReq of shipmentsRequest) {
        shipmentRequestId.push(shipmentReq.shipmentId);
      }
      const shipmentsRequestId: ArrayShipmentRequest = {
        shipmentsReq: shipmentRequestId
      };
      this.shipment = await this._shipmentProvider.getShipmentsRequest(this.shipperOid, shipmentsRequestId);
      await this.getEvidencesOfShipment();
    }
  }

  /**
   * @description Get evidences of Shipment
   */
  public async getEvidencesOfShipment() {
    const files: Array<PictureData> = [];
    for (const shipmentReq of this.shipment) {
      shipmentReq.detalles.forEach(detail => {
        files.push(...detail.evidencias.foto);
      });
    }
    await this.getEvidencesFiles(files);
  }

  /**
   * @description Get evidence Files
   * @param {Array<PictureData} files Array of PictureData with all pictures of shipment
   */
  public async getEvidencesFiles(files: Array<PictureData>): Promise<void> {
    const additionalData: Array<FileData> = [];
    const evidencesFile = [];
    const urlImages: Array<string> = [];
    await this.getEvidencesAPI();

    files.forEach(evidence => {
      evidencesFile.push({ _id: evidence.img['_id'] });
    });

    const ids = { evidencesFile };
    const evidenceImages = await this.evidencesProvider.getFilesByOids(ids);
    const images: Array<File> = [];

    evidencesFile.forEach(id => {
      const img = evidenceImages.find(evidence => evidence._id === id._id);
      if (img) { images.push(img); }
    });

    images.forEach(img => {
      urlImages.push(img.nombre !== null ? `${URL}${img.nombre}` : URL_NOT_IMAGE);
    });

    evidencesFile.forEach(id => {
      additionalData.push({ id: id._id });
    });

    this.pdfEvidences = [];
    await this.fileConversorService.fileConversor(urlImages, false, null, additionalData).then((res) => {
      this.pdfEvidences = res;
    });
  }

  /**
   * @description Gets Evidences file from Evidence API
   */
  public async getEvidencesAPI(): Promise<void> {
    try {
      for (const shipment of this.shipment) {
        const params = {
          shipmentFolio: shipment.id,
          tenantId: this.shipperOid
        };
        const evidenceByShipment = await this.evidencesProvider.getByShipment(params);
        const ordersIdentifierBody = {
          ordersIdentifiers: []
        };
        if (evidenceByShipment) {
          for (const evidence of evidenceByShipment.evidence.ordersEvidence) {
            ordersIdentifierBody.ordersIdentifiers.push(evidence.orderId);
          }
          this.auxEvidenceFile = [];
          const fullOrders = await this.orderProvider.getOrdersByIdentifiers(ordersIdentifierBody);
          const currentOrder = fullOrders.orders.find(order => order._id === this.orderOid);
          this.evidenceURI = [];
          for (const evidence of evidenceByShipment.evidence.ordersEvidence) {
            if (currentOrder && (evidence.orderId === currentOrder.identifier)) {
              for (const file of evidence.file) {
                this.evidenceURI.push(this.buildFileURI(file, evidence.orderId, shipment.id));
                const auxObj = {
                  type: evidence.notes,
                  folio: evidence.folio,
                  file: evidence.file
                };
                this.auxEvidenceFile.push(auxObj);
              }
            }
          }
          this.evidencesFileAPI = await this.fileConversorService.fileConversor(this.evidenceURI, false);
        }
      }
    } catch (error) {
      this.toast.errorAlert(this.miniCarouselTagsTranslated.errorGettingEvidences);
    }
  }

  /**
   * @description Builds File URI
   * @param {string} fileName Current file name
   * @param {string} orderId Order Identifier
   * @param {string} shipmentFolio Current shipment folio request (S2)
   * @returns {string} A file URI build
   */
  private buildFileURI(fileName: string, orderId: string, shipmentFolio: string): string {
    const fileURI = BASEURI + shipmentFolio + SLASH + orderId + SLASH + fileName;

    return fileURI;
  }

  public previousPicture(): void {
    if (this.actualPosition <= 0) {
      this.actualPosition = this.images.length - 1;
    } else {
      this.actualPosition--;
    }
  }

  public nextPicture(): void {
    if (this.actualPosition >= this.images.length - 1) {
      this.actualPosition = 0;
    } else {
      this.actualPosition++;
    }
  }

  /**
   * @description It validates if a URL string has pdf extension
   * @param file url file
   */
  public validatePDFExtension(file: string): boolean {
    file = file?.toLowerCase();
    return file?.endsWith(PDF_EXTENSION);
  }

  /**
   * @description It downloads the files in a zip using the downloadEvidences of the downloadService
   */
  public onDownloadEvidences(): void {
    this.toast.processingAlert();
    const orderName = this.cleanString(this.orderFolio);
    const allEvidences: Array<EvidenceToDisplay> = this.fileImages;
    this.downloadService.downloadEvidences(orderName, allEvidences, this.isIncidence);
    this.toast.closeProcessing();
    this.toast.successAlert(this.miniCarouselTagsTranslated.toastSuccessDownload);
  }

  /**
   * @description Convert OrderApi to OrderData object
   * @param {OrdersApi} orderApi orderApi object
   * @returns {OrderData} OrderData object with ordersApi values
   */
  public convertToOrderData(orderApi: OrdersApi): OrderData {
    let order: OrderData;
    if (orderApi) {
      order = {
        _id: orderApi._id,
        account: {
          _id: orderApi.account._id,
          name: orderApi.account.name
        },
        additionalComments: undefined,
        appointmentHour: undefined,
        boxes: undefined,
        creationDate: undefined,
        deliveryDate: orderApi.deliveryDate,
        destination: {
          address: orderApi.destination.address,
          latitude: orderApi.destination.latitude,
          longitude: orderApi.destination.longitude,
          municipality: orderApi.destination.municipality,
          name: orderApi.destination.name,
          postalCode: orderApi.destination.postalCode,
          settlement: orderApi.destination.settlement,
          state: orderApi.destination.state
        },
        discount: undefined,
        evidences: orderApi.evidences,
        folio: orderApi.folio,
        identifier: orderApi.identifier,
        inRouting: undefined,
        internalReference: orderApi.internalReference,
        invoice: orderApi.invoice,
        orderGrouper: orderApi.orderGrouper ? orderApi.orderGrouper : undefined,
        origin: undefined,
        pallets: undefined,
        pieces: undefined,
        products: undefined,
        shipperId: undefined,
        status: orderApi.status,
        tenantId: undefined,
        type: orderApi.type,
        volume: undefined,
        warehouseId: undefined,
        weight: undefined
      };
    }

    return order;
  }

  /**
   * @description Convert FilesToShow object to EvidenceToDisplay object
   * @param {FilesToShow} filesToShow FilesToShow to convert
   * @returns {EvidenceToDisplay} EvidenceToDisplay object
   */
  public convertFilesToShowToEvidenceToDisplay(filesToShow: FilesToShow): EvidenceToDisplay {
    const evidence: EvidenceToDisplay = {
      address: filesToShow.address,
      file: filesToShow.file,
      id: filesToShow.id,
      name: filesToShow.name,
      isMobilityEvidence: filesToShow.isMobilityEvidence,
      status: filesToShow.status,
      url: filesToShow.url
    };

    return evidence;
  }

  /**
   * @description Select the images to download
   * @param {Array<string>} fileIds images ids
   * @param {Array<EvidenceToDisplay>} evidences evidences to download
   */
  public getImages(fileIds: Array<string>, evidences: Array<EvidenceToDisplay>): void {
    this.evidenceImages = [];

    fileIds.forEach(id => {
      const img = evidences.find(evidence => evidence.id === id);
      if (img) {
        this.evidenceImages.push(img);
      }
    });
  }

  /**
   * @description Disable download incidence/evidence in one file button
   * @param {Array<string>} files An array with url files
   * @returns A true or false value
   */
  public disabledDownloadOneFile(files: Array<string>): boolean {
    if (files?.length >= 1) {
      const extensions = [];
      for (const file of files) {
        extensions.push(file.split(AppConstants.DOT_CHAR).pop());
      }
      const isBelowThreshold = (currentValue) => currentValue === AppConstants.PDF_PREFIX;
      return extensions.every(isBelowThreshold);
    } else if (files?.length === 0) {
     return true;
    }
  }

  /**
   * @description Event fires when Download evidences in PDF format
   */
  public async onDownloadPDFEvidences(): Promise<void> {
    try {
      const fileIds = this.order?.evidences?.map(element => element.file);
      const fileName = `${this.cleanString(this.orderFolio)}${PDF_EXTENSION}`;
      this.toast.processingAlert();
      if (this.isIncidence) {
        let incidences: Array<EvidenceToDisplay> = await this.buildIncidenceToPdf();
        incidences = incidences.filter(urlFile => urlFile.url.split(AppConstants.DOT_CHAR).pop() !== AppConstants.PDF_PREFIX);
        await this.downloadService.downloadPDFEvidences(incidences, this.order, this.shipment, fileName, undefined, true);
      } else {
        this.getImages(fileIds, this.pdfEvidences);
        if (this.auxEvidenceFile && this.auxEvidenceFile.length) {
          this.addingMissingDataEvidences();
          this.evidenceImages = [...this.evidenceImages, ...this.evidencesFileAPI];
        }
        this.evidenceImages = this.evidenceImages.filter(e => e.url.split(AppConstants.DOT_CHAR).pop() !== AppConstants.PDF_PREFIX);
        await this.downloadService.downloadPDFEvidences(this.evidenceImages, this.order, this.shipment, fileName);
        this.toast.successAlert(this.miniCarouselTagsTranslated.toastSuccessDownload);
      }
    } catch (error) {
      this.toast.errorAlert(this.miniCarouselTagsTranslated.toastErrorDownload);
    } finally {
      this.toast.closeProcessing();
    }
  }

  /**
   * @description Creates an object with neccesary data for generate incidence pdf file
   * @returns {Array<EvidenceToDisplay>} An array with incidence data
   */
  public async buildIncidenceToPdf(): Promise<Array<EvidenceToDisplay>> {
    const incidencesBuilt = [];
    for await (const incidence of this.incidencesData) {
      const imageFile = await this.buildIncidenceImage(incidence.url, incidence.img['nombre']);
      const incidenceBuilt: EvidenceToDisplay = {
        file: imageFile,
        name: incidence.img['nombre'],
        isMobilityEvidence: false,
        description: incidence.descripcion,
        type: incidence.tipo,
        url: incidence.url
      };
      incidencesBuilt.push(incidenceBuilt);
    }

    return incidencesBuilt;
  }

  /**
   * @description Creates an object tyoe File from url image
   * @param {string} urlImage Url image from blob storage
   * @param {string} fileName Current file name
   * @returns {any} An object File type
   */
  public async buildIncidenceImage(urlImage: string, fileName: string): Promise<any> {
    let file;
    await fetch(urlImage)
    .then(res => res.blob())
    .then(blob => {
      file = new File([blob], fileName);
    });

    return file;
  }

  /**
   * @description Adding missing data for evidences objects
   */
  public addingMissingDataEvidences(): void {
    for (const auxEvidence of this.auxEvidenceFile) {
      for (let i = 0; i < this.evidencesFileAPI.length; i++) {
        if (this.evidencesFileAPI.find(e => e.name === auxEvidence['file'][i])) {
          this.evidencesFileAPI[i]['type'] = !auxEvidence['type'] ? EMPTY_STRING : auxEvidence['type'];
          this.evidencesFileAPI[i]['folio'] = auxEvidence['folio'];
        }
      }
    }
  }

  /**
   * @description When an image is clicked, it opens the file preview dialog
   * @param fileUrl URL string
   */
  public async onViewImage(fileUrl: string): Promise<void> {
    const obj = this.fileImages.filter(i => i.url === fileUrl);
    let incidenceFound = this.incidencesData ?
    this.incidencesData.find((incidence: IncidenceData) => incidence.url === fileUrl) : undefined;

    if (!incidenceFound && this.incidencesData && this.incidencesData.length) {
      for (const incidence of this.incidencesData) {
        if (!incidence.urls || !incidence.urls.length) {
          continue;
        }
        const currentImg = incidence.urls.find(url => url === fileUrl);
        if (currentImg) {
          incidenceFound = incidence;
        }
      }
    }

    const params = {
      data: {
        title: this.miniCarouselTagsTranslated.dialogPreviewTitle,
        file: obj[0].file
      },
      width: DIALOG_WIDTH
    };

    if (this.displayInfo) {
      let evidenceExtraData = [];
      evidenceExtraData = this.evidenceAPIInfo.filter(evidence => {
        for (let k = 0; k < evidence.urls.length; k++) {
          if (evidence.urls[k] === fileUrl) {
            return evidence;
          }
        }
      });
      if (!evidenceExtraData.length) {
        evidenceExtraData = this.mobilityInfo.filter(mobility => {
          for (let k = 0; k < mobility.evidence.length; k++) {
            if (mobility.evidence[k].evidence.url === fileUrl) {
              return mobility;
            }
          }
        });
      }
      params.data[KEY_DISPLAY_INFO] = true;
      params.data[KEY_EVIDENCE_INFO] = evidenceExtraData[0];
      params.data[KEY_INCIDENCE_INFO] = { incidence: incidenceFound, isIncidence: this.isIncidence };
    }

    this.dialog.open(DialogFilePreviewComponent, params);
  }


  private cleanString(name: string): string {
    return name.replace(/[|&;$%@"<>()+,/\.?#]/g, '-');
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getCarouselLabels();
      },
      (error) => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Gets Carouse component Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getCarouselLabels(): Promise<void> {
    this.miniCarouselTagsTranslated = await this._languageTranslateService.getLanguageLabels(LanguageConstants.MINI_CAROUSEL)
    .catch(error => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }
}
