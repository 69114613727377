import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { Reason, ReasonResponse } from '../../interfaces/reason';

const apiUrl = environment.apiUrl;

@Injectable()
export class ReasonProvider {
  constructor(private http: HttpClient, private appService: AppService) { }

  /**
   * @description Generate request for create a new reason.
   * @param {Reason} body - To send in the request.
   * @returns {Promise<ReasonResponse>} Response of shipper.
   */
  public async createReason(body: Reason): Promise<ReasonResponse> {
    return await this.http.post<ReasonResponse>(`${apiUrl}/reasons/`, body).toPromise();
  }

  /**
   * @description Gets all related shipper reasons by given shipperId.
   * @param {string} shipperId - Optional Param to get related reasons.
   * @returns {Promise<ReasonResponse>} Shipper found reasons.
   */
  public async getReasons(shipperId?: string): Promise<ReasonResponse> {
    const shipperOid = shipperId ?? this.appService.getShipperOid();

    return await this.http.get<ReasonResponse>(`${apiUrl}/reasons/${shipperOid}`).toPromise();
  }

  /**
   * @description Gets reason by identifier.
   * @param {string} shipperId - Optional Param to get related reasons.
   * @param {string} identifier - Reason id to find.
   * @returns {Promise<ReasonResponse>} Reason found.
   */
  public async getReasonByIdentifier(identifier: string, shipperId?: string): Promise<ReasonResponse> {
    const shipperOid = shipperId ?? this.appService.getShipperOid();

    return await this.http.get<ReasonResponse>(`${apiUrl}/reasons/${shipperOid}/identifier/${identifier}`).toPromise();
  }
}
