import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { AppConstants } from '../../constants/app-constants.constants';
import { CommunicationService } from '../../services/communication';
import { GenericRegexp } from '../../regexp/generic.regexp';
import { LanguageConstants } from '../../constants/language.constants';
import { LanguageChangeEventService } from '../../services/translate/language-change-event.service';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { SearchMatSelectResults } from '../../interfaces/search-mat-select';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

import { ReplaySubject, Subject,Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss', '../../app.component.scss']
})
export class SearchFilterComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('multiSelect') multiSelect: MatSelect;
  @Input() public controlName: string;
  @Input() public enableInput?: boolean;
  @Input() public errorMessageText?: string;
  @Input() public isRequiredInput?: boolean;
  @Input() public selectLabel: string;
  @Input() public results: Array<SearchMatSelectResults>;

  protected _onDestroy = new Subject<void>();
  public fieldMultiCtrl: UntypedFormControl = new UntypedFormControl();
  public fieldMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredFieldsCache: Array<SearchMatSelectResults>;
  public filteredFieldsMulti: ReplaySubject<SearchMatSelectResults[]> = new ReplaySubject<SearchMatSelectResults[]>(1);
  public isChecked: boolean;
  public isIndeterminate: boolean;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public searchFilterLabels: any;
  public showToggleAllCheckbox: boolean;

  constructor(
    private comService: CommunicationService,
    private toastrService: ToastrAlertsService,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService
  ) {
    this.setLanguage();
   }

  /**
   * @description Angular init Lifecycle
   */
  public async ngOnInit(): Promise<void> {
    await this.initComponent();
  }

  /**
   * @description Angular onChanges Lifecycle
   */
  public ngOnChanges(): void {
    if (this.filteredFieldsCache && this.filteredFieldsCache.length > 0) {
      this.showToggleAllCheckbox = true;
    } else {
      this.showToggleAllCheckbox = false;
    }
    this.filteredFieldsMulti.next(this.results?.slice());
    this.fieldMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSearch();
      });
  }

  /**
   * @description Method that is invoked immediately after Angular
   * has completed initialization of a component's view
   */
  public ngAfterViewInit(): void {
    this.setInitialValue();
  }

  /**
   * @description Angular destroy Lifecycle
   */
  public ngOnDestroy(): void {
    this.languageSuscription?.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * @description Sets the initial value after the filtered Fields are loaded initially
   */
  protected setInitialValue(): void {
    this.filteredFieldsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: SearchMatSelectResults, b: SearchMatSelectResults) => a && b && a._id === b._id;
      });
  }

  /**
   * @description Set select/unselect state for all Checkbox items
   */
  protected setToggleAllCheckboxState(): void {
    if (this.fieldMultiCtrl && this.fieldMultiCtrl.value) {
      this.isIndeterminate = this.fieldMultiCtrl.value.length > 0 && this.fieldMultiCtrl.value.length < this.results.length;
      this.isChecked = this.fieldMultiCtrl.value.length > 0 && this.fieldMultiCtrl.value.length === this.results.length;
    }
  }


  /**
   * @description Removes special characters for the current word passed
   * @param {string} word Current word to normalize
   * @returns {string} Returns a string normalized
   */
  private normalizeWord(word: string): string {
    const normalizedWord = word.toLowerCase().normalize(AppConstants.NORMALIZATION).replace(GenericRegexp.DIACRITIC_REMOVE,
      AppConstants.EMPTY_STRING);
    return normalizedWord;
  }

  /**
   * @description Filter the coincidences for the current search
   */
  protected filterSearch(): void {
    if (!this.results) {
      return;
    }
    let search = this.fieldMultiFilterCtrl.value;
    if (search.length >= 3) {
      if (!search) {
        this.filteredFieldsMulti.next(this.results.slice());
        return;
      } else {
        search = this.normalizeWord(search);
      }
      const coincidences = this.results.filter(result => this.normalizeWord(result.name).indexOf(search) > -1);
      this.filteredFieldsMulti.next(coincidences);
    } else {
      this.filteredFieldsMulti.next(this.results);
    }
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getSearchFilterLabels();
      },
      () => {
        this.toastrService.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets Language Labels from translate JSON files.
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
      .catch(() => {
        this.toastrService.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Gets select search labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getSearchFilterLabels(): Promise<void> {
    this.searchFilterLabels =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.SEARCH_FILTER_LABELS)
        .catch(() => {
          this.toastrService.errorAlert(this.languageLabels.errorGettingLabels);
        });
  }

  /**
   * @description method to validate the new results and remove the same results selected from previous search
   * @param {Array<SearchMatSelectResults>} results results actually selected to make the comparisons
   * @returns {Array<SearchMatSelectResults>} just results that have not been selected yet
   */
  public removeDuplicatedResults(results: Array<SearchMatSelectResults>): Array<SearchMatSelectResults> {
    for (const result of results) {
      if (this.results.find(element => element._id === result._id)) {
        const indexResult = this.results.findIndex(element => element._id === result._id);
        this.results.splice(indexResult, 1);
      }
    }

    return this.results;
  }

  /**
   * @description Listen for multi select field value changes
   */
   private listenInputChanges(): void {
    this.fieldMultiCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.setToggleAllCheckboxState();
    });
  }

  /**
   * @description Emit selection changes by user
   * @param {MatSelectChange} selection Option selection by user
   */
  public emitSelectionChange(selection: MatSelectChange): void {
    let items = selection.value;
    items.map(item => item.controlName = this.controlName);
    if (!items.length) {
      items = [];
      items.push({ controlName: this.controlName });
    }
    this.comService.searchMatSelection(items);
  }


  /**
   * @description Listen for toggle all selection
   * @param {boolean} selectAllValue Boolean flag to indicates that all options are selelected
   */
  public toggleSelectAll(selectAllValue: boolean): void {
    if (selectAllValue) {
      const items = this.results;
      items.forEach(item => item.controlName = this.controlName);
      this.comService.searchMatSelection(items);
    } else {
      this.comService.searchMatSelection([{ controlName: this.controlName }]);
    }
    this.filteredFieldsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.fieldMultiCtrl.patchValue(val);
        } else {
          this.fieldMultiCtrl.patchValue([]);
        }
      });
  }

  /**
   * @description Start the tasks in the component.
   */
  private async initComponent(): Promise<void> {
    this.fieldMultiCtrl = new UntypedFormControl(null);
    this.filteredFieldsCache = [];
    this.isIndeterminate = false;
    this.isChecked = false;
    this.listenInputChanges();
    this.subscribeLanguageChangeEvent();
    await this.getLanguageTags();
    await this.getSearchFilterLabels();
    await this.clearFilters();
    this.setValidation();
  }

  /**
   * @description Sets the field as required if 'isRequiredInput' is true.
   */
  public setValidation(): void {
    if (this.isRequiredInput) {
      this.fieldMultiCtrl.setValidators(Validators.required);
      this.fieldMultiCtrl.updateValueAndValidity();
    }
  }

  /**
   * @description Reset options selected in the search filter component.
   */
  public async clearFilters(): Promise<void> {
    this.comService.resetComponentSubscribe().subscribe(async () => {
      await this.initComponent();
    })
  }
}
