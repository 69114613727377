import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { AccountProvider } from '../../providers/accounts/account-provider.service';
import { Accounts, Carrier } from '../../interfaces';
import { AppService } from '../../app.service';
import { EvidencesLabel } from '../../pages/reports/evidences/evidences.label';
import { LABEL_ORDER_PRINTING } from '../../pages/orders/label-order-printing/constants/label-order-printing.constants';
import { ORDER_STATUS } from '../../constants/order.constants';
import { RangeTypeList } from '../../interfaces/warehouse';
import { ReportFilterLabels } from './constants/report-filter-label.constants';
import { ReportFilterTags } from './interface/report-filter-tags.interface';
import { ReportSearch } from './interface/report-search.interface';
import { SelectSearchFields } from '../../../app/enums';
import { ShipmentViewLabels } from '../../pages/shipments/shipment-view/shipment-view.labels';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';
import { UnitOfTime } from '../../enums/unit-of-time.enum';
import { ViewReportLabels } from './constants/view-report-label';

import { isEmpty as _isEmpty } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-report-filter-search',
  templateUrl: './report-filter-search.component.html',
  styleUrls: ['../../app.component.scss']
})
export class ReportFilterSearchComponent implements OnInit {
  @Input() public carriers?: Array<Carrier>;
  @Input() public isCarrier?: boolean;
  @Input() public isOrderDate?: boolean;
  @Input() public isShipmentDate?: boolean;
  @Input() public isExpanded: boolean;
  @Input() public showAccounts?: boolean;
  @Input() public showOrderStatus?: boolean;
  @Input() public title: string;
  @Output() public reportFiltersData: EventEmitter<ReportSearch>;

  public accountControlName: string;
  public accounts: Array<Accounts>;
  public dateRangeType: string;
  public datesRangeTypes: Array<RangeTypeList>;
  public disabledDate: string;
  public disabledString: string;
  public emptyString: string;
  public filterSearchForm: UntypedFormGroup;
  public labels: ReportFilterTags;
  public maxBeginDate: Date;
  public maxEndDate: Date;
  public minBeginDate: Date;
  public minEndDate: Date;
  public multipleSelectSearch: boolean;
  public ordersStatus: Array<string>;
  public showAccountSelectSearch: boolean;
  public undisabledString: string;

  constructor(
    private accountProvider: AccountProvider,
    private appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private toast: ToastrAlertsService
  ) {
    this.accountControlName = SelectSearchFields.AccountControlName;
    this.reportFiltersData = new EventEmitter<ReportSearch>();
    this.labels = ReportFilterLabels;
    this.emptyString = '';
    this.multipleSelectSearch = true;
    this.showAccountSelectSearch = false;
  }

  /**
   * @Description Method to initialize the component
   */
  public async ngOnInit(): Promise<void> {
    this.accounts = [];
    this.ordersStatus = [];
    for (const status of ORDER_STATUS) {
      this.ordersStatus.push(status.value);
    }
    this.initForm();
    this.initSelectsData();
    if (this.showAccounts) {
      await this.getAccountNames();
    }
  }
  
  /**
   * @description Gets current shipper accounts names
   */
  private async getAccountNames(): Promise<void> {
    try {
      const shipperOid = this.appService.getShipperOid();
      const shipperAccounts = await this.accountProvider.getAccounts(shipperOid);
      for (const account of shipperAccounts.cuentas) {
        this.accounts.push({ _id: account._id, name: account.nombre });
      }
    } catch (error) {
      this.toast.warningAlert(EvidencesLabel.toastErrorGettingAccounts);
    }
  }

  /**
   * @description Handles changes on range date range type select
   * @param {string} selectedRangeType The type selected
   */
  public dateRangeTypeOnChange(selectedRangeType: string): void {
    this.dateRangeType = selectedRangeType;
    this.disabledDate = this.undisabledString;
    this.maxBeginDate = new Date();
    this.maxEndDate = new Date();
    this.minBeginDate = undefined;
    this.filterSearchForm.controls.beginDate.setValue(null);
    this.filterSearchForm.controls.endDate.setValue(null);
    this.filterSearchForm.controls.beginDate.enable();
    this.filterSearchForm.controls.endDate.enable();
  }

  /**
   * @description Handles changes on begin date datepicker
   * @param {Date} beginDate The date selected
   */
  public beginDateOnChange(beginDate: Date): void {
    const endDateValue = this.filterSearchForm.get(LABEL_ORDER_PRINTING.END_DATE_FORM_KEY).value;
    if (endDateValue) {
      const diffDays: number = moment(endDateValue).diff(moment(beginDate), UnitOfTime.days);
      if (diffDays > this.labels.maxDays || diffDays < this.labels.zero) {
        this.toast.warningAlert(ShipmentViewLabels.warningMaximumPeriodSearch + this.labels.maxDays.toString()
          + ShipmentViewLabels.warningMaximumPeriodSearchComplement);
        this.filterSearchForm.controls.endDate.setValue(null);
      }
    }
    this.minEndDate = beginDate;
    const dateWithDaysAdded: string = moment(beginDate).add(this.labels.maxDays, UnitOfTime.days).format();
    this.maxEndDate = new Date(dateWithDaysAdded);
    if (this.maxEndDate > new Date()) {
      this.maxEndDate = new Date();
    }
  }

  /**
   * @description handle search event
   */
  public onSearch(): void {
    const maxHours = 23;
    const maxMinutesSeconds = 59;
    const maxMiliseconds = 99;
    this.filterSearchForm.value.endDate = new Date(this.filterSearchForm.value.endDate.setHours(maxHours, maxMinutesSeconds,
    maxMinutesSeconds, maxMiliseconds));
    if (this.accounts && !this.filterSearchForm.value.accounts.length) {
      this.filterSearchForm.value.accounts = this.accounts.map(account => account._id);
    }

    if (this.showOrderStatus && !this.filterSearchForm.value.orderStatus.length) {
      this.filterSearchForm.value.orderStatus = this.ordersStatus;
    }

    this.reportFiltersData.emit(this.filterSearchForm.value);
  }

  /**
   * @description handle disabling of search event
   * @returns {boolean} if the valid form is correct return true
   */
  public isDisableButton(): boolean {
    return !this.filterSearchForm.valid;
  }

  /**
   * @description Initialize orders general form
   */
  private initForm(): void {
    this.maxEndDate = new Date();
    this.maxBeginDate = new Date();

    if ((this.isCarrier ?? false)) {
      this.filterSearchForm = this.formBuilder.group({
        dateRangeType: [null, Validators.required],
        beginDate: new UntypedFormControl({value: this.emptyString, disabled: true}, [Validators.required]),
        endDate: new UntypedFormControl({value: this.emptyString, disabled: true}, [Validators.required]),
        carriers: new UntypedFormControl({value: this.emptyString, disabled: false}, [Validators.required]),
      });
    } else {
      this.filterSearchForm = this.formBuilder.group({
        accounts: new UntypedFormControl([]),
        orderStatus: new UntypedFormControl([]),
        dateRangeType: [null, Validators.required],
        beginDate: new UntypedFormControl({value: this.emptyString, disabled: true}, [Validators.required]),
        endDate: new UntypedFormControl({value: this.emptyString, disabled: true}, [Validators.required])
      });
    }
  }

  /**
   * @description Initialize selects show data.
   */
  private initSelectsData(): void {
    this.datesRangeTypes = [];
    this.isOrderDate = this.isOrderDate ?? true;
    this.isShipmentDate = this.isShipmentDate ?? true;

    if (!this.isOrderDate && !this.isShipmentDate) {
      this.datesRangeTypes.push({ type: ViewReportLabels.orderCreationDateRangeType });
      this.datesRangeTypes.push({ type: ViewReportLabels.shipmentCreationDateRangeType });
    } else {
      if (this.isOrderDate) {
        this.datesRangeTypes.push({ type: ViewReportLabels.orderCreationDateRangeType });
      }
      if (this.isShipmentDate) {
        this.datesRangeTypes.push({ type: ViewReportLabels.shipmentCreationDateRangeType });
      }
    }

    if (this.datesRangeTypes.length === 1) {
      this.filterSearchForm.controls.dateRangeType.setValue(this.datesRangeTypes[0].type);
      this.dateRangeTypeOnChange(this.datesRangeTypes[0].type);
    }
  }

  /**
   * @description Initialize selects show data.
   * @param {Date} date Begin date selected.
   * @returns {number} Numbers of days in a month.
   */
  private daysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }
}
