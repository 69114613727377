export enum ConfigurationLabels {
  dialogContinue = 'Continuar',
  dialogDefaultFocusText = 'Esta acción no podrá deshacerse',
  dialogDefaultQuestion = '¿Deseas continuar?',
  dialogDeleteAccept = 'Eliminar',
  dialogDeleteCancel = 'Cancelar',
  dialogDeleteResumeBegin = 'Toda la información de la interfaz POD para la cuenta ',
  dialogDeleteResumeEnd = ' se eliminará.',
  dialogDeleteTitle = 'Eliminar Interfaz POD',
  dialogSaveAccept = 'Aceptar',
  dialogSaveCancel = 'Cancelar',
  dialogSaveQuestion = '¿Deseas continuar?',
  dialogSaveResume = 'Los cambios serán aplicados para todo el sistema.',
  dialogSaveTitle = 'Guardar la configuración.',
  dialogWarehouseAccept = 'Cambiar',
  dialogWarehouseCancel = 'Cancelar',
  dialogWarehouseResume = 'Toda la configuración e información que realices estará relacionada con la bodega que estás a punto de asignar',
  dialogWarehouseTitle = 'Cambio de Bodega',
  dragAndDropLabel = '(En formato PNG o JPG con un tamaño máximo de 1MB y resolución máxima de 800x800px.)',
  errorChargeExist = 'El cargo ya existe',
  errorChargeSave = 'Ocurrió un error al guardar los cargos',
  errorInputCedisHours = 'El valor máximo de horas a ingresar es de 120',
  errorInputCedisMinutes = 'El valor máximo de minutos a ingresar  es de 60',
  errorSaveAccounts = 'Ocurrió un error al tratar de guardar las cuentas',
  errorTemperature = 'No se pudo obtener las temperaturas del embarcador',
  errorTenant = 'No se pudo registrar el tenant',
  errorToSaveCostConfig = 'Ocurrió un error al guardar la configuración de costeo',
  insertChargeName = 'Ingrese el nombre del cargo',
  noAccountsError = 'No se encontraron cuentas de este embarcador, verifica la información',
  noAccountsFound = 'No se encontraron cuentas coincidentes',
  searchAccountsError = 'Ocurrió un error al realizar la búsqueda de las cuentas',
  podAzureConnectionError = 'Ocurrió un error al intentar conectarse con el servidor de PODs',
  resumeSaveTenderingConfig = 'Los cambios serán aplicados para todo el sistema',
  successCharges = 'Se guardaron correctamente los cargos',
  successOnConfigSave = 'La configuración del sistema ha sido guardada correctamente.',
  successTenant = 'Se registró correctamente el tenant en el microservicio de costeo',
  titleSaveTenderingConfig = 'Guardar la configuración',
  toastErrorCreate = 'Ocurrió un error al guardar la configuración de POD. Intenta más tarde',
  toastErrorCreateApport = 'Ocurrió un error al guardar el esquema de prorrateo. Intenta más tarde',
  toastErrorDelete = 'Ocurrió un error al eliminar la configuración de POD. Intenta más tarde',
  toastErrorConfig = 'Ocurrió un error al tratar de guardar la información',
  toastErrorSavingFieldsSettings = 'Ha ocurrido un error al tratar de guardar las configuraciones de los campos',
  toastSaveSuccessTenderingConfig = 'La configuración del sistema ha sido guardada correctamente',
  toastSuccessCreate = 'La configuración de POD ha sido guardada correctamente',
  toastSuccessCreateApport = 'La configuración del sistema ha sido guardada correctamente',
  toastSuccessDelete = 'La configuración de POD ha sido eliminada correctamente',
  withoutInformation = 'Sin información'
}

export enum ConfigurationTags {
  accidents = 'Siniestros',
  account = 'Cuenta',
  accountsAppointmentOrdersRequired = 'Cuentas',
  actions = 'Acciones',
  activateDetention = 'Activar la detención de órdenes',
  activateTolerance = 'Activar tolerancia de ingreso a CEDIS',
  activateUseUcc = 'Activar uso de UCCs para órdenes',
  addCharge = 'Agregar cargo',
  addPodInterface = 'Agregar Interfaz POD',
  appointmentOrdersRequired = 'Embarcar solo órdenes con cita',
  appointmentOrdersRequiredInfo = 'Al encender esta opción, todas las órdenes asignadas al embarque' +
  ' deben tener su cita de entrega registrada',
  apportionmentParams = 'Parámetros de Prorrateo',
  assignationBy = 'ASIGNACIÓN POR',
  autoAssignByPlaceholder = 'Asignar por',
  automatic = 'Automática',
  automaticAssignation = 'Asignación automática',
  boxesPlaceholder = 'Cajas',
  boxesRequired = 'Boxes are ',
  changeWarehouse = 'Cambiar Bodega',
  chargeName = 'Nombre del cargo',
  checkChargesAndDiscounts = 'Validar cargos y descuentos',
  checkChargesAndDiscountsTooltipBegin = 'Al encender esta opción, deberás validar cualquier cargo adicional y/o descuento ',
  checkChargesAndDiscountsTooltipEnd = 'que asocies a una propuesta de facturación antes de poder generarla.',
  clean = 'Limpieza',
  cost = 'Costos',
  costingConfigurationsCardTitle = 'Configuraciones de costeo',
  createTenant = 'Crear Tenant',
  daysOfferActive = 'Días que las solicitudes estarán en línea',
  daysRangeActiveRequired = 'Los días que las solicitudes estarán disponibles es requerido',
  deliveryTime = 'Tiempo de entrega',
  displayCarrierNameInOffer = 'Mostrar el nombre del transportista en la oferta',
  distancePlaceholder = 'Distancia',
  distanceRequired = 'Distance is ',
  driverSettingsTitle = 'TEP Driver',
  earlyArrive = 'Tolerancia de llegada anticipada',
  shipOrdersNotSelected = 'Embarcar órdenes no entregadas',
  errorDecimalNotAllowed = 'Solo se permiten números enteros',
  excedingMax = 'excede el máximo',
  fieldsSettings = 'Configuración de campos',
  fieldsSettingsInfo = 'Permite decidir si para una ubicación (origen y destino), el RFC será un campo obligatorio o no.',
  freightCostRequiredSlideToggleTextPt1 = 'Sólo confirmar embarques',
  freightCostRequiredSlideToggleTextPt2 = ' con costo de flete',
  freightCostRequiredTooltip = 'Al encender esta opción, no podrás confirmar los embarques hasta que cuenten con una tarifa' +
  ' de costo de flete asociada ya sea creando la tarifa en el catálogo o asignando un transporte diferente',
  freightsPlaceholder = 'Fletes',
  halftProgressBar = 'El total debe alcanzar el 100%, por favor incremente algunos parámetros',
  hintMaximumQuantity = 'Cantidad máxima',
  hours = 'Hrs',
  hoursIs = 'El valor de las horas',
  hoursMax = 'El valor máximo de horas',
  invalidNumber = 'Solo números mayores a cero',
  isMaxHours = 'es 120',
  isMaxMinutes = 'es 59',
  isMaxPercentageError = 'Los porcentajes exceden el 100%',
  isMinPercentageError = 'Los porcentajes no concretan el 100%',
  isMinTemperatureError = 'La Temperatura Mínima es mayor que la Temperatura Máxima',
  isRequired = 'es requerido',
  invoiceProviderTitle = 'Configuración de timbrado',
  invoiceProviderLabel = 'Proveedor de servicios de timbrado',
  lateArrive = 'Tolerancia de llegada demorada',
  logoDescription = 'El logo se verá reflejado en el panel de usuario la próxima vez que se inicie sesión',
  logoTitle = 'Logo',
  maneuversPlaceholder = 'Maniobras',
  manual = 'Manual',
  manualAssignation = 'Asignación manual',
  maxDriverInactiveTime = 'Minutos de desconexión',
  maxDriverInactiveTimeInfo = 'Cantidad de minutos sin reporte de nueva ubicación por parte de la aplicación TEP Driver para ser considerado como una Desconexión.',
  activateDetentionInfo = 'Permite al embarcador indicar si las órdenes con incidencia serán detenidas, para autorizar o detener su salida.',
  activateUseUccInfo = 'Permite al embarcador el manejo de UCCs (identificador único del cliente. por cada caja que tendrá entrada en almacén)',
  maxTemperature = 'La temperatura mínima es ',
  maxTemperaturePlaceHolder = 'Temperatura máxima (°C)',
  maxTemperatureSymbol = '+',
  maximumOffersPlaceholder = 'Máximo de ofertas',
  maximumOffersRequired = 'El máximo de ofertas es requerido',
  maximumshipmentsPlaceholder = 'Número máximo de viajes por línea de transporte',
  minTemperature = 'La temperatura mínima es ',
  minTemperaturePlaceHolder = 'Temperatura mínima (°C)',
  minTemperatureSymbol = '-',
  minutes = 'Min',
  minutesIs = 'El valor de los minutos',
  minutesMax = 'El valor máximo de minutos',
  neccessaryRFCForLocations = 'RFC obligatorio para la ubicación',
  newCharge = 'Cargo nuevo',
  noWarehouses = 'No se cuentan con bodegas',
  number = 'Número',
  orderOffersRequired = 'Selecciona un parámetro para ordenar las ofertas',
  palletsPlaceholder = 'Tarimas',
  palletsRequired = 'Pallets are ',
  paramToAutoAssignRequired = 'El parámetro para hacer la auto-asignación es requerido',
  paymentTypeRequiredTooltip = 'Al encender esta opción se deberá seleccionar un tipo de cobro válido para poder confirmar el embarque.',
  paymentTypeRequiredText = 'Tipo de cobro requerido',
  periodicity = 'Periodicidad',
  piecesPlaceholder = 'Piezas',
  piecesRequired = 'Pieces are ',
  podInterfaceTitle = 'Interfaz POD',
  publicPortalTitle = 'Portal público',
  quality = 'Calidad',
  quantityIs = 'La cantidad',
  ratingOptions = 'Opciones de calificación',
  ratingTitle = 'CALIFICACIONES',
  refrigeredTemperature = 'Temperatura Refrigerada',
  required = 'required',
  requiredAccount = 'Cuenta(s)',
  requiredMark = '*',
  saturedProgressBar = 'El total excede el 100%, por favor baje algunos parámetros',
  save = 'Guardar',
  saveChanges = 'Guardar',
  savePercentages = 'Guardar porcentajes',
  security = 'Seguridad',
  serviceTypes = 'Tipo de servicio',
  serviceTypesRequired = 'Service type is  ',
  services = 'Servicios',
  selectAll = 'Seleccionar todas',
  shipNotDeliveredOrders = 'Embarcar órdenes No entregadas',
  shipRejectedOrders = 'Embarcar órdenes rechazadas',
  showOrdersInPublicPortal = 'Mostrar órdenes en el portal público',
  sortOffersPlaceholder = 'Ordenar ofertas por',
  specialConcepts = 'CONCEPTOS ESPECIALES',
  temperatureScale = 'Escala de Temperatura',
  tenantRegistred = 'El tenant ya está registrado ',
  tenantUnregistred = 'El tenant no está registrado. Da clic ',
  timePeriod = 'Periodo de tiempo',
  title = 'Configuración',
  titleCedisAccess = 'Acceso a CEDIS',
  titleShipment = 'Configuración para embarcar órdenes',
  titleTemperature = 'Rango de Temperaturas para Hieleras',
  titleTenant = 'Registrar tenant en Costeo',
  titleTendering = 'Licitación de transporte (Tendering)',
  titleWarehouse = 'Configuraciones de Bodega',
  totalCalcule = 'Cálculo total: ',
  totalPercentage = '/100%',
  transportDatesRequired = 'Fechas de transporte obligatorias',
  transportDatesRequiredInfo = 'Permite especificar si las fechas solicitadas en la asignación de transporte serán de carácter obligatorio',
  variableCharges = 'Cargos Variables',
  volumePlaceholder = 'Volumen',
  volumeRequired = 'Volume is ',
  weightPlaceholder = 'Peso',
  weightRequired = 'Weight is ',
  withholdings = 'Retenciones',
  zeroPercentage = '%',
  zeroTemperature = '0 °C'
}

export enum ActionLog {
  appointmentOrdersRequiredDisabled = 'Las fechas de cita obligatorias de las Órdenes han sido desactivadas',
  appointmentOrdersRequiredEnabled = 'Las fechas de cita obligatorias de las Órdenes han sido activadas',
  checkChargesAndDiscountsDisabled = 'La configuración "validar cargos y descuentos" ha sido desactivada',
  checkChargesAndDiscountsEnabled = 'La configuración "validar cargos y descuentos" ha sido activada',
  paymentTypeRequiredDisabled= 'La opción "Tipo de cobro requerido" ha sido deshabilitada',
  paymentTypeRequiredEnabled= 'La opción "Tipo de cobro requerido" ha sido activada',
  requiredFreightCostDisabled = 'La opción "Sólo confirmar embarques con costo de flete" ha sido desactivada',
  requiredFreightCostEnabled = 'La opción "Sólo confirmar embarques con costo de flete" ha sido activada',
  transportDatesRequiredDisabled = 'Las fechas de transporte obligatorias han sido desactivadas',
  transportDatesRequiredEnabled = 'Las fechas de transporte obligatorias han sido activadas'
}
