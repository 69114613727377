<mat-grid-list cols="4" rowHeight="1:.15">
  <mat-grid-tile colspan="3" rowspan="1">
    <span class="dialog-title">
      <strong>
        <p>{{ data.title }}</p>
      </strong>
    </span>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" rowspan="1">
    <mat-icon class="dialog-close-icon" (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
</mat-grid-list>
<br>
<mat-grid-list cols="3" rowHeight="1:.25">
  <form [formGroup]="rejectionReasonForm">
    <mat-grid-tile colspan="1" rowspan="4">
      <img [src]="data.imgRef" width="95%" height="95%" alt="Delete icon" />
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <span class="dialog-resume">
        <p>{{ data.resume }}</p>
      </span>
    </mat-grid-tile>
    <mat-grid-tile  colspan="2" rowspan="1">
      <span class="text-direction dialog-resume"><strong>{{ data.focusText }}</strong></span>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <span class="text text-direction">{{ data.question }}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-form-field class="text-direction">
        <input
          placeholder="{{ 'orderRemoveDialogLabels.reason' | translate }}"
          matInput
          minlength="3"
          maxlength="250"
          formControlName="rejectionReason"
          [required]="true" />
        <mat-error *ngIf="rejectionReasonForm.controls['rejectionReason'].hasError('required')">
          {{ 'orderRemoveDialogLabels.reason' | translate }} <strong>{{ 'orderRemoveDialogLabels.required' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </form>
</mat-grid-list>
  <div class="add-file">
    <button
      (click)="openFileSelector()"
      class="icon-buttonAddComp"
      isIconButton="true">
      <mat-icon class="white-icon" aria-label="Browse and add new files">add</mat-icon>
    </button>
    <input
      type="file"
      name="files"
      id="inputFiles"
      (change)="selectFiles()"
      hidden
      accept=".jpg,.jpeg,.png"/>
  <app-drag-and-drop
    [filesInDropZone]="filesInDropZone"
    [filesAddedByButton]="filesAddedByButton"
    [multiple]="multipleFiles"
    [allowedFormatExtensions]="allowedExtensions"
    (filesDropped)="filesDropped($event)">
  </app-drag-and-drop>
</div>
<mat-grid-list cols="2" rowHeight="5:1">
  <mat-grid-tile colspan="1" rowspan="1">
  </mat-grid-tile>
  <mat-grid-tile class="button-container" colspan="1" rowspan="1">
    <button
      mat-raised-button
      class="secondary-button"
      (click)="onClickClose()">{{ data.button1 }}
    </button>
    <button
    mat-raised-button
    cdkFocusInitial
    [disabled]="!rejectionReasonForm.valid"
    class="primary-button align-right"
    (click)="acceptHandler()">
      {{ data.button2 }}
    </button>
  </mat-grid-tile>
</mat-grid-list>
