export const AppConstants = {
  ACCEPTED_DIALOG_RESPONSE: 'Accepted',
  AFFIRMATIVE_VALUES: [
    'si',
    'sí'
  ],
  ASCENDENT: 'asc',
  BREAK_LINE: '\n',
  CARRIAGE_RETURN: '\r',
  CLOSED: 'closed',
  COLON: ': ',
  COMMA_SPACE: ', ',
  CONFIRM: 'Confirm',
  CURRENCY_KEYWORD: 'currency',
  CURRENCY_SIGN: '$ ',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_SEPARATOR: 'T',
  DEF_FIXED_DECIMALS: 3,
  DEFAULT_COLLAPSED_HEIGHT: '35px',
  DEFAULT_EXPANDED_HEIGHT: '35px',
  DIALOG_STANDARD_SIZE: '650px',
  DIALOG_STANDARD_HEIGHT: '300px',
  DOT_CHAR: '.',
  EMPTY_STRING: '',
  END_DAY_HOUR: {
    hours: 23,
    minutes: 59,
    seconds: 59
  },
  GOOGLE_SUCCESS_RESPONSE: 'OK',
  GRID_CONFIGS: 'gridConfigs',
  IMAGES_FILES_FORMAT_ALLOWED: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'],
  INTERWAREHOUSE_POSITION: 6,
  INVALID_INDEX: -1,
  IS_CONSULTING_SHIPMENT_KEY: 'IsConsultingShipmentDetails',
  ISR_FACTOR: 0.0125,
  IVA_FACTOR: 0.16,
  KEY_BLOBNAME: 'blobName',
  KEY_EN: 'en',
  KEY_ES: 'es',
  KEY_SASURL: 'sasUrl',
  MAX_LENGTH_DOCS: 300,
  MAX_TIME: { hour: 23, miliseconds: 999, minsAndSecs: 59 },
  MEXICO_COUNTRY: 'México',
  MEXICO_KEY: 'MX',
  MIN_DISTANCE_TRAVELED: '0.01',
  MIN_FACTOR: 0.01,
  NORMALIZATION: 'NFD',
  NO_APPLY: 'N/A',
  NUMBER_DIGITS: '1.0',
  OBJECT_ID_KEY: '_id',
  PAGE_SIZE_OPTIONS: [25, 50, 100],
  PANEL_CLASS: 'dialog-success-detail',
  ORDER_BULK_LOAD_MODULE_NAME: 'orders-bulk-load',
  PDF_PREFIX: 'pdf',
  PERCENTAGE_VALUE: 100,
  RETENTION_FACTOR: 0.04,
  SHIPPER_DEFAULT_LOGO: 'assets/icons/solutions/tepLogo2.jpg',
  SLASH: '/',
  SOLUTION_NAME: 'TEP',
  SPANISH_KEY: 'es',
  SYSTEM_DEFAULT_LOGO: 'assets/icons/solutions/tep-logo.svg',
  TIME_OUT_RESTART_SCF_GRID: 100,
  UNITED_STATES_DOLLAR: 'USD',
  UNDERSCORE: '_',
  US_KEY: 'US',
  US_NUMBER_FORMAT: 'en-US',
  USER_INFO_KEY: 'userInfo',
  WHITE_SPACE: ' ',
  ZERO_STRING: '0',
  ZERO: 0
};
