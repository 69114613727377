<mat-grid-list cols="2" rowHeight="1:.10">
  <mat-grid-tile>
    <span class="dialog-title">
      <p>{{ data.title }}</p>
    </span>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-icon
      style="cursor: pointer"
      class="dialog-close-icon"
      (click)="onClickClose()">
      close
    </mat-icon>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" rowspan="1">
    <span class="dialog-resume" *ngIf="selected.value !== 3">
      <p>{{ data.resume }}</p>
    </span>
    <span class="dialog-resume" *ngIf="selected.value === 3">
      <p>{{ labels.dialogResume }}</p>
    </span>
  </mat-grid-tile>
</mat-grid-list>
<br>
<div class="locationSearchBy" *ngIf="selected.value !== 3">
  <mat-grid-list cols="6" rowHeight="1:.7">
    <mat-grid-tile 
      colspan="5"
      rowspan="1"
      class="form-alignment">
      <mat-tab-group
        [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)"
        class="align-left">
        <mat-tab [label]=labels.postalCode>
          <br>
          <form [formGroup]="postalCodeFormGroup">
            <mat-form-field
              class="mat-form-dialog-location"
              *ngIf="selected.value === 0">
              <input
                matInput
                minlength="0"
                maxlength="5"
                [placeholder]=labels.postalCode
                formControlName="postalCodeValue"
                (keypress)="onKeyPressCode($event)"
                required
                (keyup.enter)="getLocations(selected.value)">
              <mat-error *ngIf="searchValueFormControl.hasError('required')">
                {{ labels.postalCodeIs }}
                <strong>{{ labels.required }}{{ labels.suffixO }}</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab [label]=labels.name>
          <br>
          <form [formGroup]="nameFormGroup">
            <mat-form-field class="mat-form-dialog-location">
              <input
                matInput
                [placeholder]=labels.name
                formControlName="nameValue"
                required
                maxlength="120"
                (keyup.enter)="getLocations(selected.value)">
              <mat-error *ngIf="searchValueFormControl.hasError('required')">
                {{ labels.nameIs }}
                <strong>{{ labels.required }}{{ labels.suffixO }}</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab [label]=labels.identifier>
          <br>
          <form [formGroup]="identifierFormGroup">
            <mat-form-field class="mat-form-dialog-location">
              <input
                matInput
                maxlength="120"
                [placeholder]=labels.identifier
                formControlName="identifier"
                required
                (keyup.enter)="getLocations(selected.value)">
              <mat-error
                *ngIf="identifierFormGroup.controls['identifier'].hasError('required')">
                {{ labels.identifierIs }}
                <strong>{{ labels.required }}{{ labels.suffixO }}</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab [label]=labels.manual> </mat-tab>
      </mat-tab-group>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <span *ngIf="selected.value === 0">
        <button
          id="postalCodeSearchButton"
          mat-raised-button
          [disabled]="!postalCodeFormGroup.valid"
          class="primary-button align-right align-top"
          (click)="getLocations(selected.value)">
          {{ data.button }}
        </button>
      </span>
      <span *ngIf="selected.value === 1">
        <button
          id="nameSearchButton"
          mat-raised-button
          [disabled]="!nameFormGroup.valid"
          class="primary-button align-right align-top"
          (click)="getLocations(selected.value)">
          {{ data.button }}
        </button>
      </span>
      <span *ngIf="selected.value === 2">
        <button
          id="identifierSearchButton"
          mat-raised-button
          [disabled]="!identifierFormGroup.valid"
          class="primary-button align-right align-top"
          (click)="getLocations(selected.value)">
          {{ data.button }}
        </button>
      </span>
    </mat-grid-tile>
  </mat-grid-list>
  <br>
  <div *ngIf="visible" class="table">
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="dataSource" aria-describedby="tableLocation">
      <ng-container matColumnDef="identifier">
        <th id="locationId" mat-header-cell *matHeaderCellDef>
          {{ labels.identifier }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.identifier | nodata }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th id="locationName" mat-header-cell *matHeaderCellDef>
          {{ labels.name }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th id="locationAddress" mat-header-cell *matHeaderCellDef>
          {{ labels.address }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="postalCode">
        <th id="locationPostalCode" mat-header-cell *matHeaderCellDef>
          {{ labels.postalCodeAbbreviation }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.postalCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th id="locationState" mat-header-cell *matHeaderCellDef>
          {{ labels.state }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.state }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="getRecord(row)"></tr>
    </table>
  </div>
</div>
<div [hidden]="!visible || selected.value === 3">
  <mat-paginator
    [pageSizeOptions]="[2, 4]"
    showFirstLastButtons></mat-paginator>
</div>

<div class="locationSearchManually" *ngIf="selected.value === 3">
  <mat-grid-list cols="1" rowHeight="1:.1">
    <mat-grid-tile>
      <mat-tab-group
        [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)"
        class="align-left">
        <mat-tab [label]=labels.postalCode></mat-tab>
        <mat-tab [label]=labels.name></mat-tab>
        <mat-tab [label]=labels.identifier></mat-tab>
        <mat-tab [label]=labels.manual></mat-tab>
      </mat-tab-group>
    </mat-grid-tile>
  </mat-grid-list>
  <app-dialog-dynamic-location
    [orderType]='orderType'
    [locationType]="locationType"
    [locationInfo]="locationInfo"
    [validateUniqueName]="false">
  </app-dialog-dynamic-location>
</div>
