import { Injectable } from '@angular/core';

import { AppConstants } from '../../app/constants/app-constants.constants';

@Injectable()

/**
 * @description Service to process and build a date provided to custom output using library native of Date.
 */
export class DateFormatService {
  constructor() { }

  /**
   * @description Receives a date to transform it to string.
   * @param {Date} dateToProcess - Date you want to process and transform.
   * @param {string} separator - Separator will be put it between day, month and year values.
   * @returns {string} Date provided but in string as format MMDDYYYY.
   */
  public buildDateInMMDDYYYYFormat(dateToProcess: Date, separator: string): string {
    let date;
    let month;
    const digitsToSubstract = -2;
    const maxDigits = 2;
    date = dateToProcess.getDate();
    month = dateToProcess.getMonth() + 1;
    const year = dateToProcess.getFullYear();
    date = date.toString().padStart(maxDigits, AppConstants.ZERO_STRING);
    month = month.toString().padStart(maxDigits, AppConstants.ZERO_STRING);

    return `${month}${separator}${date}${separator}${String(year).slice(digitsToSubstract)}`;
  }
}
