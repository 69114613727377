<div id="print-section" class="style-print-section">
  <div class="div-header-container">
    <div class="div-logo-container">
      <img
        alt="Logo"
        src='{{logoUrl}}'
        id="logo"
        (error)="setDefaultImage($event);">
    </div>
    <div id="title-container" class="div-title-container">
      <div class="div-right-title">
        <p>{{ 'loadPlanTags.title' | translate }}</p>
      </div>
      <div class="div-left-title">
        <span>{{shipmentData.shipmentId}}</span>
        <ngx-barcode
          id="barcode"
          [bc-element-type]="svgProperty"
          [bc-format]="codeProperty"
          [bc-value]="shipmentData.shipmentId"
          [bc-display-value]="false"
          [bc-width]="1"
          [bc-font-size]="10"
          [bc-height]="30"
          [bc-margin]="0"
          [bc-margin-top]="0"
          [bc-margin-bottom]="0"
          [bc-margin-left]="5"
          [bc-margin-right]="0">
        </ngx-barcode>
      </div>
    </div>
  </div>

  <div class="section-divider">
    <div class="subtitle">
      <h4 class="subheader">{{ 'loadPlanTags.shipmentGeneralInfo' | translate }}</h4>
    </div>
    <div class="divider">
      <hr>
    </div>
  </div>

  <div class="general-info-container">
    <div [ngClass]="{'full-info-container': shipmentData.specialRequirements.requirements.length === 0 && shipmentData.specialRequirements.otherRequirements === '',
     'info-container': shipmentData.specialRequirements.requirements.length > 0 || shipmentData.specialRequirements.otherRequirements !== ''}">
     <table aria-describedby="table-info-resume" class="info-resume">
        <td>
          <tr>
            <td>
              {{ 'loadPlanTags.account' | translate }}
            </td>
            <td>
              <div>
                <span *ngFor="let account of shipmentUsedAccounts;let i = index">
                  <strong *ngIf="account.name === ''">{{ 'loadPlanTags.noInfo' | translate }} </strong>
                  <strong *ngIf="i < shipmentUsedAccounts.length - 1">{{ account.name }}, </strong>
                  <strong *ngIf="i === shipmentUsedAccounts.length - 1">{{ account.name }}</strong>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'loadPlanTags.tripType' | translate }}
            </td>
            <td>
              <strong *ngIf="shipmentData.tripType !== ''">
                {{ shipmentData.tripType }}
              </strong>
              <strong *ngIf="shipmentData.tripType === ''">
                {{ 'loadPlanTags.noInfo' | translate }}
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'loadPlanTags.internalReference' | translate }}
            </td>
            <td>
              <strong>
                {{ shipmentData.internalReference && shipmentData.internalReference !== '' ? shipmentData.internalReference :
                ('loadPlanTags.noInfo' | translate) }}
              </strong>
            </td>
          </tr>
        </td>
        <td></td>
        <td>
          <tr>
            <td>
              {{ 'loadPlanTags.shipmentServiceType' | translate }}
            </td>
            <td>
              <strong>
                {{ setServiceTypeLabel(shipmentData.shipmentServiceType) }}
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'loadPlanTags.shipmentLoadType' | translate }}
            </td>
            <td>
              <strong>
                {{ setLoadTypeLabel(shipmentData.loadType) }}
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              {{ 'loadPlanTags.typeOfCharge' | translate }}
            </td>
            <td>
              <strong>
                {{ setTypeOfChargeLabel(shipmentData.typeOfCharge) ? setTypeOfChargeLabel(shipmentData.typeOfCharge) :
                'loadPlanTags.noInfo' | translate }}
              </strong>
            </td>
          </tr>
        </td>
      </table>
    </div>

    <div class="requirements-container" *ngIf="shipmentData.specialRequirements.requirements.length || shipmentData.specialRequirements.otherRequirements">
      <table aria-describedby="table-special-requirements" *ngIf="shipmentData.specialRequirements.requirements.length" class="special-requirements">
        <th id="th-special-requirements"></th>
        <tr>
          <td>
            {{ 'loadPlanTags.specialRequirements' | translate }}
          </td>
        </tr>
        <tr>
          <td>
            <div *ngFor="let requirement of shipmentData.specialRequirements.requirements" class="single-special-requirements">
              <mat-icon class="dot-icon">
                fiber_manual_record
              </mat-icon>
              {{ requirement }}
            </div>
          </td>
        </tr>
      </table>

      <table aria-describedby="table-additional-requirements" *ngIf="shipmentData.specialRequirements.otherRequirements" class="additional-requirements">
        <th id="th-additional-requirements"></th>
        <tr>
          <td>
            {{ 'loadPlanTags.additionalRequirements' | translate }}
          </td>
        </tr>
        <tr>
          <td>
            <mat-icon class="dot-icon">
              fiber_manual_record
            </mat-icon>
            {{ shipmentData.specialRequirements.otherRequirements }}
            </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="section-divider">
    <div class="subtitle">
      <h4 class="subheader">{{ 'loadPlanTags.assignedTransportation' | translate }}</h4>
    </div>
    <div class="divider">
      <hr>
    </div>
  </div>

  <table aria-describedby="table-carrier-info">
    <th id="th-carrier-info"></th>
    <tr>
      <td>
        <table aria-describedby="table-transport-carrier">
          <th id="th-transport-carrier"></th>
          <tr>
            <td>
              {{ 'loadPlanTags.carrier' | translate }}
            </td>
            <td>
              <strong *ngIf="shipmentData.transport.transportCarrier !== ''"> {{ shipmentData.transport.transportCarrier }} </strong>
              <strong *ngIf="shipmentData.transport.transportCarrier === ''"> {{ 'loadPlanTags.noInfo' | translate }} </strong>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table aria-describedby="table-transport-vehicle">
          <th id="th-transport-vehicle"></th>
          <tr>
            <td>
              {{ 'loadPlanTags.vehicleType' | translate }}
            </td>
            <td>
              <strong *ngIf="shipmentData.transport.vehicle !== ''"> {{ shipmentData.transport.vehicle }} </strong>
              <strong *ngIf="shipmentData.transport.vehicle === ''"> {{ 'loadPlanTags.noInfo' | translate }} </strong>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table aria-describedby="table-transport-plates">
          <th id="th-transport-plates"></th>
          <tr>
            <td>
              {{ 'loadPlanTags.plates' | translate }}
            </td>
            <td>
              <strong *ngIf="shipmentData.transport.plates !== ''"> {{ shipmentData.transport.plates }} </strong>
              <strong *ngIf="shipmentData.transport.plates === ''"> {{ 'loadPlanTags.noInfo' | translate }} </strong>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table aria-describedby="table-transport-driver">
          <th id="th-transport-driver"></th>
          <tr>
            <td>
              {{ 'loadPlanTags.driver' | translate }}
            </td>
            <td>
              <strong *ngIf="shipmentData.transport.driver !== ''"> {{ shipmentData.transport.driver }} </strong>
              <strong *ngIf="shipmentData.transport.driver === ''"> {{ 'loadPlanTags.noInfo' | translate }} </strong>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table aria-describedby="table-transport-helper">
          <th id="th-transport-helper"></th>
          <tr>
            <td>
              {{ 'loadPlanTags.helper' | translate }}
            </td>
            <td>
              <strong *ngIf="shipmentData.transport.helper !== '' && shipmentData.transport.helper !== null"> {{ shipmentData.transport.helper }} </strong>
              <strong *ngIf="shipmentData.transport.helper === '' || shipmentData.transport.helper === null "> {{ 'loadPlanTags.doesNotApply' | translate }} </strong>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <div class="section-divider" *ngIf="showCollectionPointsSection">
    <div class="subtitle">
      <h4 class="subheader">{{ 'loadPlanTags.collectionPoints' | translate }}</h4>
    </div>
    <div class="divider">
      <hr>
    </div>
  </div>

  <div class="general-info-container" *ngIf="showCollectionPointsSection">
    <div class="collection-points-container">
      <table aria-describedby="table-collection-points">
        <th></th>
        <tr>
          <td>
            <div class="single-collection-points" *ngFor="let warehouse of departureWarehouses">
              <mat-icon class="dot-icon">
                fiber_manual_record
              </mat-icon>
              {{ warehouse }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="section-divider">
    <div class="subtitle">
      <h4 class="subheader">{{ 'loadPlanTags.wareData' | translate }}</h4>
    </div>
    <div class="divider">
      <hr>
    </div>
  </div>

  <table aria-describedby="table-resume-indicator" class="table-resume-indicators">
    <th id="th-resume-indicator"></th>
    <tr>
      <td class="number-indicator">{{ stops.length }} </td>
      <td class="text-indicator">{{ 'loadPlanTags.totalStops' | translate }}</td>
      <td class="number-indicator">{{ shipmentData.orders.length}}</td>
      <td class="text-indicator">{{ 'loadPlanTags.totalOrders' | translate }}</td>
      <td class="number-indicator">{{ totalPallets }}</td>
      <td class="text-indicator">{{ 'loadPlanTags.totalPallets' | translate }}</td>
      <td class="number-indicator">{{ totalBoxes }}</td>
      <td class="text-indicator">{{ 'loadPlanTags.totalBoxes' | translate }}</td>
      <td class="number-indicator">{{ totalPieces }}</td>
      <td class="text-indicator">{{ 'loadPlanTags.totalPieces' | translate }}</td>
    </tr>
  </table>

  <div *ngFor="let dividedStop of dividedStops; let i = index">
    <div class="stop-detail-container" *ngFor='let stop of dividedStops[i]; let j = index'>
      <div class="full-text-divider">
        <div id="stop-header" class="subtitle">
          <span class="label-stop">
            {{ 'loadPlanTags.stop' | translate }} {{ stop.stop }} &middot;
            <strong class="uppercase-text" *ngIf="stop.address"> {{ stop.address }} </strong>
            <strong class="uppercase-text" *ngIf="!stop.address"> {{ 'loadPlanTags.noInfo' | translate }} </strong>
          </span>
        </div>
        <div class="divider">
          <hr>
        </div>
      </div>

      <table aria-describedby="table-order-info" mat-table [dataSource]="dataSource.data[i][j]">
        <ng-container matColumnDef="folioId">
          <th id="th-folio" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.folioId' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.folioId">{{ element.folioId }}</span>
            <span *ngIf="!element.folioId">{{ 'loadPlanTags.noInfo' | translate }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="orderId">
          <th id="th-orderId" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.orderId' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.orderId">{{ element.orderId }}</span>
            <span *ngIf="!element.orderId">{{ 'loadPlanTags.noInfo' | translate }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="invoice">
          <th id="th-invoice" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.invoice' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.invoice">{{ element.invoice }}</span>
            <span *ngIf="!element.invoice">{{ 'loadPlanTags.noInfo' | translate }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="deliveryDate">
          <th id="th-deliveryDate" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.deliveryDate' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.deliveryDate">{{ element.deliveryDate | date: 'dd/MM/yyyy' }}</span>
            <span *ngIf="!element.deliveryDate">{{ 'loadPlanTags.noInfo' | translate }}</span>
            <br *ngIf="element.appointmentHour">
            <span *ngIf="element.appointmentHour">{{ element.appointmentHour }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalPallets">
          <th id="th-pallets" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.pallets' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.totalPallets">{{ element.totalPallets }}</span>
            <span *ngIf="!element.totalPallets">{{ 'loadPlanTags.noInfo' | translate }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalBoxes">
          <th id="th-boxes" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.boxes' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.totalBoxes">{{ element.totalBoxes }}</span>
            <span *ngIf="!element.totalBoxes">{{ 'loadPlanTags.noInfo' | translate }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalPieces">
          <th id="th-pieces" mat-header-cell *matHeaderCellDef>{{ 'loadPlanTags.pieces' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.totalPieces">{{ element.totalPieces }}</span>
            <span *ngIf="!element.totalPieces">{{ 'loadPlanTags.noInfo' | translate }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
      </table>
    </div>
    <div class="page-break" *ngIf="i < (dividedStops.length - 1)"></div>
  </div>

  <div flexLayout="row">
    <div fxFlex="100" class="additional-info-boxes">
      <div><p class="additional-info-text">{{ 'loadPlanTags.additionalComments' | translate }}</p></div>
    </div>
  </div>

  <div flexLayout="row">
    <div fxFlex="50" class="additional-info-boxes">
      <p class="additional-info-text">{{ 'loadPlanTags.supervisorySignature' | translate }}</p>
    </div>
    <div fxFlex="50" class="additional-info-boxes">
      <p class="additional-info-text">{{ 'loadPlanTags.carrierAcceptance' | translate }} {{ shipmentData.transport.transportCarrier | nodata }}</p>
    </div>
  </div>

</div>
