import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { AuthService } from '../../../authentication/auth.service';
import { ConfirmationDialogLabels } from '../../../interfaces/status-changes';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

@Component({
  selector: 'app-dialog-status-changes-confirmation',
  styleUrls: ['../../../app.component.scss', './dialog-status-changes-confirmation.component.scss'],
  templateUrl: './dialog-status-changes-confirmation.component.html'
})

/**
 * Component to display previous warning messages to user before apply status changes.
 */
export class DialogStatusChangesConfirmationComponent implements OnInit {

  /**
   * @description Builds an instance from necessary services in this component.
   * @param {ConfirmationDialogLabels} data - Dialog data.
   * @param {AppService} appService - Main service of project.
   * @param {AuthService} authService - Service with authentication utils.
   * @param {MatDialogRef<DialogStatusChangesConfirmationComponent>} dialogRef - Instance of this component as dialog.
   * @param {ToastrAlertsService} toast - Service to display differents toasters to user.
   * @param {FormBuilder} builder - Service to can use forms in  this component.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogLabels,
    private appService: AppService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<DialogStatusChangesConfirmationComponent>,
    private toast: ToastrAlertsService,
    private readonly builder: FormBuilder
  ) {
    this.hidePassword = true;
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public ngOnInit(): void {
    this.initForm();
  }

  /**
   * @description Checks password to confirm changes, if password is correct pass ticket support provided to apply changes.
   */
  public async onConfirm(): Promise<void> {
    const password = this.confirmationFormGroup.controls.password.value;
    const supportTicket = this.confirmationFormGroup.controls.supportTicket.value;
    try {
      await this.checkEnteredPassword(password, supportTicket ? supportTicket.trim() : null);
    } catch (error) {
      this.toast.warningAlert(this.data.passwordError);
    }
  }

  /**
   * @description Initialize the confirmation form.
   */
  private initForm(): void {
    this.confirmationFormGroup = this.builder.group({
      password: new FormControl(null),
      supportTicket: new FormControl(null)
    });
  }

  /**
   * @description Verifies if password entered is correct to confirm changes.
   * @param {string} password - Password entered by user to check.
   * @param {string} supportTicket - Support ticket entered by user to register in the change.
   */
  private async checkEnteredPassword(password: string, supportTicket: string): Promise<void> {
    try {
      this.appService.loaderStatus(true);
      const request = {
        password,
        username: this.appService.getShipperNameCookie(),
      };
      const response = await this.authService.postAuth(request);
      this.appService.loaderStatus(false);

      if (response) {
        this.dialogRef.close(supportTicket ?? AppConstants.CONFIRM);
      }
    } catch (error) {
      this.appService.loaderStatus(false);
      this.toast.warningAlert(this.data.passwordError);
    }
  }

  /**
   * @description Changes flag status to show or hide entered password in password input.
   */
  public showOrHidePassword(): void {
    this.hidePassword = !this.hidePassword
  }

  public confirmationFormGroup: FormGroup;
  public hidePassword: boolean;
}
