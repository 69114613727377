import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScfGridColumn } from 'scf-library';
import { Subscription } from 'rxjs';

import {
  AccountBillingScheme, ConceptAccountBillingScheme, OriginDestinationAccountBillingScheme
} from '../../../interfaces/account-billing-scheme';
import {
  AdditionalChargeAndDiscount, CustomerInvoiceBody, CustomerInvoiceDetailBody, CustomerInvoiceDetailData, CustomerInvoiceDetailLabels, Order
} from '../../../interfaces/invoiceProposal';
import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { CUSTOMER_INVOICE_DETAIL } from './dialog-customer-invoice-detail.constants';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { OrdersApi, Shipments } from '../../../interfaces';
import {
  PaymentType
} from '../../../pages/costs/account-billing-schemes/account-billing-schemes-create/account-billing-schemes-create.constants';
import { ScfGridConfig } from '../../../interfaces/scfgrid';
import { ShipmentProvider } from '../../../providers/shipments/shipment-provider.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

/**
 * @description - Component that display a dialog with the provided invoice proposal data.
 */
@Component({
  selector: 'app-dialog-customer-invoice-detail',
  styleUrls: ['./dialog-customer-invoice-detail.component.scss', '../../../app.component.scss'],
  templateUrl: './dialog-customer-invoice-detail.component.html'
})
export class DialogCustomerInvoiceDetailComponent implements OnInit {
  public accountBillingScheme: AccountBillingScheme;
  public customerInvoiceData: CustomerInvoiceBody;
  public customerInvoiceDetailLabels: CustomerInvoiceDetailLabels;
  public dataSource: Array<CustomerInvoiceDetailBody>;
  public dataSourceAvailable: boolean;
  public displayedColumns: Array<ScfGridColumn>;
  public isScfGridReady: boolean;
  public languageLabels: ILanguageLabels
  public languageSuscription: Subscription;
  public orderList: Array<OrdersApi>;
  public proposalOrders: Array<Order>;
  public scfGridConfig: ScfGridConfig;
  public shipmentList: Array<Shipments>;
  public shipperId: string;

  /**
   * @description Initialize component required services.
   * @param {CustomerInvoiceDetailData} customerDetailData - Provided invoice proposal.
   * @param {AppService} appService - App general services.
   * @param {LanguageChangeEventService} languageChangeEventService - Language change event services.
   * @param {LanguageTranslateService} languageTranslateService - Language translation services.
   * @param {ShipmentProvider} shipmentProvider - Shipment provider service.
   * @param {OrderProvider} orderProvider - Order provider service.
   * @param {ToastrAlertsService} toast - Toast alerts service.
   * @param {MatDialogRef<DialogCustomerInvoiceDetailComponent>} dialogRef - Dialog reference.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public customerDetailData: CustomerInvoiceDetailData,
    private appService: AppService,
    private languageChangeEventService: LanguageChangeEventService,
    private languageTranslateService: LanguageTranslateService,
    private shipmentProvider: ShipmentProvider,
    private orderProvider: OrderProvider,
    private toast: ToastrAlertsService,
    public dialogRef: MatDialogRef<DialogCustomerInvoiceDetailComponent>
  ) {
    this.setLanguage();
  }

  /**
   * @description Gets Customer Invoice Labels from translate JSON files.
   */
  public async getCustomerInvoiceDetailLabels(): Promise<void> {
    try {
      this.customerInvoiceDetailLabels = await this.languageTranslateService.getLanguageLabels(
        LanguageConstants.CUSTOMER_INVOICE_DETAIL_LABELS);
    } catch (error) {
      error.message = this.languageLabels.errorGettingLabels;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component.
   * @returns {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getCustomerInvoiceDetailLabels();
  }

  /**
   * @description Gets Language labels from translate JSON files.
   */
  public async getLanguageLabels(): Promise<void> {
    try {
      this.languageLabels = await this.languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS);
    } catch (error) {
      error.message = this.languageLabels.errorGettingLabels;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Angular OnInit lyfecycle.
   */
  public async ngOnInit(): Promise<void> {
    this.appService.loaderStatus(true);
    this.accountBillingScheme = this.customerDetailData.accountBillingScheme;
    this.customerInvoiceData = this.customerDetailData.customerInvoiceData;
    this.dataSourceAvailable = false;
    this.isScfGridReady = false;
    this.scfGridConfig = CUSTOMER_INVOICE_DETAIL.SCF_GRID_CONFIG;
    this.proposalOrders = this.customerInvoiceData.orders;
    this.shipperId = this.appService.getShipperOid();
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    await this.getShipmentList();
    await this.getOrdersList();
    this.setDataSource();
    this.appService.loaderStatus(false);
  }

  /**
   * @description Handles the close button action.
   */
  public onClickClose(): void {
    this.dialogRef.close(CUSTOMER_INVOICE_DETAIL.CLOSED);
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey - Language key indicator.
   * @returns {void}
   */
  public setLanguage(languageKey?: string): void {
    this.languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @returns {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this.languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.isScfGridReady = false;
        this.setLanguage(key);
        setTimeout(async () => {
          await this.getLabels();
          this.buildScfGrid();
        }, AppConstants.TIME_OUT_RESTART_SCF_GRID);
      }, () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Builds an array of CustomerInvoiceDetailBody objects based on an array of InvoiceProposal objects.
   * @returns {Array<CustomerInvoiceBody>} CustomerInvoiceDetailBody array.
   */
  private buildCustomerInvoiceBody(): Array<CustomerInvoiceDetailBody> {
    const auxCustomerInvoiceDetail = [];
    for (const order of this.proposalOrders) {
      const fullOrder = this.orderList.find((orderItem: OrdersApi) => {
        return orderItem.identifier === order.identifier;
      });
      const customerInvoiceDetailBody: CustomerInvoiceDetailBody = {
        additionalCharges: order.charges ? this.getAdditionalChargesAmount(order.charges) : 0,
        folio: order.folio,
        freight: order.freight ? order.freight : 0,
        identifier: order.identifier,
        shipmentFolio: this.getShipmentFolioByOid(order.shipment),
        total: this.getCustomerChargeFreightValue(fullOrder)
      };
      customerInvoiceDetailBody.total = Number(customerInvoiceDetailBody.additionalCharges) + Number(customerInvoiceDetailBody.total);
      customerInvoiceDetailBody.additionalCharges = this.formatNumberToCurrency(Number(customerInvoiceDetailBody.additionalCharges));
      customerInvoiceDetailBody.freight = this.formatNumberToCurrency(Number(customerInvoiceDetailBody.freight));
      customerInvoiceDetailBody.total = this.formatNumberToCurrency(Number(customerInvoiceDetailBody.total));
      auxCustomerInvoiceDetail.push(customerInvoiceDetailBody);
    }

    return auxCustomerInvoiceDetail;
  }

  /**
   * @description Build necessary properties to creates SCF Grid.
   */
  private buildScfGrid(): void {
    this.initColumns();
    this.isScfGridReady = true;
  }

  /**
   * @description Converts a number into a currency-formatted string.
   * @param {number} amount - The number to be formatted.
   * @returns {string} The currency-formatted string.
   */
  private formatNumberToCurrency(amount: number): string {
    const formatter = new Intl.NumberFormat(AppConstants.US_NUMBER_FORMAT, {
      currency: AppConstants.UNITED_STATES_DOLLAR,
      style: AppConstants.CURRENCY_KEYWORD
    });

    return formatter.format(amount);
  }

  /**
   * @description Calculates the total amount of additional charges.
   * @param {Array<AdditionalChargeAndDiscount>} additionalCharges - An array of objects representing additional charges and discounts.
   * @returns {number} The total amount of additional charges as a number. If the 'additionalCharges' array is empty, it will return 0.
   */
  private getAdditionalChargesAmount(additionalCharges: Array<AdditionalChargeAndDiscount>): number {
    if (!additionalCharges.length) {
      return 0;
    }

    return additionalCharges.reduce((total: number, charge: AdditionalChargeAndDiscount) => {
      return total + charge.total;
    }, 0);
  }

  /**
   * @description Calculates costumer charge freight value according to account billing scheme.
   * @param {OrdersApi} order - Current order to set value.
   * @returns {number} Quantity calculated.
   */
  private getCustomerChargeFreightValue(order: OrdersApi): number {
    if (this.accountBillingScheme.paymentType === PaymentType.fixed) {
      return this.getFixedCharge(order);
    } else if (this.accountBillingScheme.paymentType === PaymentType.variable) {
      return this.getVariableCharge(order);
    } else if (this.accountBillingScheme.paymentType === PaymentType.originDestination) {
      return this.getOriginDestinationCharge(order);
    }
  }

  /**
   * @description Gets charge according fixed type.
   * @param {OrdersApi} order - Current order.
   * @returns {number} Quantity calculated.
   */
  private getFixedCharge(order: OrdersApi): number {
    if (this.accountBillingScheme.fixedCharge) {
      return Number((this.accountBillingScheme.fixedCharge / this.orderList.length).toFixed(AppConstants.DEF_FIXED_DECIMALS));
    } else if (!this.accountBillingScheme.fixedCharge && this.accountBillingScheme.fixedChargeFee[0].isPercentage) {
      return Number((order[CUSTOMER_INVOICE_DETAIL.CONCEPTS.freightValue] +
        (order[CUSTOMER_INVOICE_DETAIL.CONCEPTS.freightValue] * (this.accountBillingScheme.fixedChargeFee[0].cost *
          AppConstants.MIN_FACTOR))).toFixed(AppConstants.DEF_FIXED_DECIMALS));
    } else if (!this.accountBillingScheme.fixedCharge && !this.accountBillingScheme.fixedChargeFee[0].isPercentage) {
      return Number((order[CUSTOMER_INVOICE_DETAIL.CONCEPTS.freightValue] +
        this.accountBillingScheme.fixedChargeFee[0].cost).toFixed(AppConstants.DEF_FIXED_DECIMALS));
    }
  }

  /**
   * @description Gets data of every shipment for all orders of the current invoice proposal.
   */
  private async getOrdersList(): Promise<void> {
    try {
      const ordersOids = {
        ordersIds: this.getOrdersOids()
      };
      this.orderList = await this.orderProvider.getOrdersByOids(ordersOids);
    } catch (error) {
      error.message = this.customerInvoiceDetailLabels.toastMessages.getOrdersError;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Gets the invoice proposal unique shipment identifiers.
   * @returns {Array<string>} Array of shipment oids.
   */
  private getOrdersOids(): Array<string> {
    const ordersOids: Array<string> = [];

    for (const order of this.customerInvoiceData.orders) {
      if (!ordersOids.includes(order.id)) {
        ordersOids.push(order.id);
      }
    }

    return ordersOids;
  }

  /**
   * @description Gets charge according origin-destination type.
   * @param {OrdersApi} order - Current order.
   * @returns {number} Quantity calculated.
   */
  private getOriginDestinationCharge(order: OrdersApi): number {
    const billingSchemeFound = this.accountBillingScheme.originDestinations.find((billingScheme: OriginDestinationAccountBillingScheme) => {
      return billingScheme.isActive && billingScheme.vehicleType.name === order[CUSTOMER_INVOICE_DETAIL.CONCEPTS.vehicleType] &&
        billingScheme.destinationMunicipality === order.destination.municipality && billingScheme.originState === order.origin.state &&
        billingScheme.originMunicipality === order.origin.municipality && billingScheme.originPostalCode === order.origin.postalCode &&
        billingScheme.destinationPostalCode === order.destination.postalCode && billingScheme.destinationState === order.destination.state;
    });

    return (billingSchemeFound?.cost ?? 0) + order[CUSTOMER_INVOICE_DETAIL.CONCEPTS.freightValue];
  }

  /**
   * @description Returns the shipmentId based on the provided shipment Oid.
   * @param {string} shipmentOid - The shipment Oid to search for in the shipment list.
   * @returns {string} The shipmentId associated with the provided shipment Oid.
   */
  private getShipmentFolioByOid(shipmentOid: string): string {
    const shipment = this.shipmentList.find((shipmentItem: Shipments) => {
      return shipmentItem._id === shipmentOid;
    });

    return shipment?.shipmentId;
  }

  /**
   * @description Gets data of every shipment for all orders of the current invoice proposal.
   */
  private async getShipmentList(): Promise<void> {
    try {
      const shipmentsOids = this.getShipmentsOids();
      this.shipmentList = (await this.shipmentProvider.getShipmentsByOIds(this.shipperId, shipmentsOids)).shipments;
    } catch (error) {
      error.message = this.customerInvoiceDetailLabels.toastMessages.getShipmentsError;
      this.toast.errorAlert(error.message);
    }
  }

  /**
   * @description Gets the invoice proposal unique shipment identifiers.
   * @returns {Array<string>} Array of shipment oids.
   */
  private getShipmentsOids(): Array<string> {
    const shipmentsOids: Array<string> = [];
    for (const order of this.customerInvoiceData.orders) {
      if (!shipmentsOids.includes(order.shipment)) {
        shipmentsOids.push(order.shipment);
      }
    }

    return shipmentsOids;
  }

  /**
   * @description Gets charge according variable type.
   * @param {OrdersApi} order - Current order.
   * @returns {number} Quantity calculated.
   */
  private getVariableCharge(order: OrdersApi): number {
    const defaultScheme = this.accountBillingScheme.concepts.find((auxConcept: ConceptAccountBillingScheme) => {
      return auxConcept.priority === CUSTOMER_INVOICE_DETAIL.PRIORITY_CONCEPT &&
        auxConcept.tripType.includes(order[CUSTOMER_INVOICE_DETAIL.CONCEPTS.shipmentType]);
    });

    if (!defaultScheme) {
      return 0;
    }
    const concept = this.translateConcept(defaultScheme.concept);
    const unitValue = defaultScheme.cost / defaultScheme.unitKey;

    return order[concept] * unitValue;
  }

  /**
   * @description Initialize default grid columns.
   */
  private initColumns(): void {
    this.displayedColumns = [];
    this.displayedColumns.push(
      { field: CUSTOMER_INVOICE_DETAIL.DISPLAYEDCOLUMNS.folio, header: this.customerInvoiceDetailLabels.columns.folio },
      { field: CUSTOMER_INVOICE_DETAIL.DISPLAYEDCOLUMNS.identifier, header: this.customerInvoiceDetailLabels.columns.identifier },
      { field: CUSTOMER_INVOICE_DETAIL.DISPLAYEDCOLUMNS.shipmentFolio, header: this.customerInvoiceDetailLabels.columns.shipmentFolio },
      { field: CUSTOMER_INVOICE_DETAIL.DISPLAYEDCOLUMNS.freight, header: this.customerInvoiceDetailLabels.columns.freight },
      { field: CUSTOMER_INVOICE_DETAIL.DISPLAYEDCOLUMNS.additCharges, header: this.customerInvoiceDetailLabels.columns.additCharges },
      { field: CUSTOMER_INVOICE_DETAIL.DISPLAYEDCOLUMNS.total, header: this.customerInvoiceDetailLabels.columns.total }
    );
  }

  /**
   * @description Sets the data source for the scf grid table.
   */
  private setDataSource(): void {
    this.dataSource = this.buildCustomerInvoiceBody();
    this.buildScfGrid();
    this.dataSourceAvailable = true;
  }

  /**
   * @description Translates concept to get order object.
   * @param {string} concept - Concept to search.
   * @returns {string} Concept translated.
   */
  private translateConcept(concept: string): string {
    const unitTypes = [
      { label: this.customerInvoiceDetailLabels.orderConcepts.boxes, orderConcept: CUSTOMER_INVOICE_DETAIL.CONCEPTS.boxes },
      { label: this.customerInvoiceDetailLabels.orderConcepts.pallets, orderConcept: CUSTOMER_INVOICE_DETAIL.CONCEPTS.pallets },
      { label: this.customerInvoiceDetailLabels.orderConcepts.pieces, orderConcept: CUSTOMER_INVOICE_DETAIL.CONCEPTS.pieces },
      { label: this.customerInvoiceDetailLabels.orderConcepts.volume, orderConcept: CUSTOMER_INVOICE_DETAIL.CONCEPTS.volume },
      { label: this.customerInvoiceDetailLabels.orderConcepts.weight, orderConcept: CUSTOMER_INVOICE_DETAIL.CONCEPTS.weight }
    ];

    return (unitTypes.find((unit: any) => {
      return unit.label === concept.toLowerCase();
    })).orderConcept;
  }
}
