import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { DialogStandardFocus } from '../../../enums/dialog-stantdard-focus';
import { DragAndDropFile } from '../../../interfaces/drag-and-drop';
import { GenericRegexp } from '../../../regexp/generic.regexp';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

const ALLOWED_EXTENSIONS = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
const FILES_KEY = 'files';
const INPUT_FILES_KEY = 'inputFiles';

@Component({
  selector: 'app-dialog-order-remove',
  templateUrl: './dialog-order-remove.component.html',
  styleUrls: ['./dialog-order-remove.component.scss',
    '../../../app.component.scss']
})
export class DialogOrderRemoveComponent implements OnInit, OnDestroy {

  public allowedExtensions: Array<string>;
  public filesAddedByButton: FileList;
  public filesInDropZone: Array<DragAndDropFile>;
  public languageLabels: any;
  public languageSuscription: Subscription;
  public multipleFiles: boolean;
  public orderRemoveLabelsTranslated: any;
  public rejectionReasonForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderRemoveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private toast: ToastrAlertsService) {
  }

  /**
   * @description Init interface for angular component
   */
  public async ngOnInit(): Promise<void> {
    this.subscribeLanguageChangeEvent();
    this.initForm();
    this.multipleFiles = false;
    this.allowedExtensions = ALLOWED_EXTENSIONS;
    await this.getLanguageTags();
    await this.getOrderRemoveLabels();
  }

  /**
   * @description Initialize the form for select
   */
  private initForm() {
    this.rejectionReasonForm = new UntypedFormGroup({
      rejectionReason: new UntypedFormControl(null, [Validators.required,
      Validators.pattern(GenericRegexp.NON_EMPTY_STRING), Validators.minLength(3),
      Validators.maxLength(250)]),
      rejectionFiles: new UntypedFormControl(null)
    });
  }

  /**
   * @description Handler for accept button action
   */
  public acceptHandler(): void {
    this.dialogRef.close(this.rejectionReasonForm.value);
  }

  /**
   * @description Handler for close button action
   */
  public onClickClose(): void {
    this.dialogRef.close(DialogStandardFocus.CLOSED);
  }

  /**
   * @description Set the dropped files in Drag-and-Drop component to the file field in driver create form
   * @param filesInDropZone The array with all the file data emitted from Drag-and-Drop component
   */
  public filesDropped(filesInDropZone: Array<DragAndDropFile>) {
    this.rejectionReasonForm.patchValue({ rejectionFiles: filesInDropZone });
  }

  /**
   * @description Triggers the prompt file selection
   */
  public openFileSelector(): void {
    document.getElementById(INPUT_FILES_KEY).click();
  }

  /**
   * @description Gets all files from prompt
   */
  public selectFiles(): void {
    this.filesAddedByButton = undefined;
    const filesByButton = document.getElementById(INPUT_FILES_KEY);
    this.filesAddedByButton = filesByButton[FILES_KEY];
  }

  /**
   * @description Angular destroy lifecycle
   */
  public ngOnDestroy(): void {
    this.languageSuscription.unsubscribe();
  }

  /**
   * @description React to the SCF language change event setting the configuration in the interface.
   * @param {string} languageKey Optional language key string, default is spanish 'es'
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description React to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getOrderRemoveLabels();
      }, () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Get Order remove labels from translate JSON files.
   */
  public async getOrderRemoveLabels(): Promise<void> {
    this.orderRemoveLabelsTranslated =
      await this._languageTranslateService.getLanguageLabels(LanguageConstants.ORDER_REMOVE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Get Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageTags(): Promise<void> {
    this.languageLabels = await this._languageTranslateService.getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

}
