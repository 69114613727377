export const REASON_PROPERTIES = {
  create: '../../../assets/create_warehouse.svg',
  delete: '../../../assets/eliminate.svg',
  widthDefault: '650px'
};

export const REASON_CONSTANTS = {
  COLLAPSED_HEIGHT: '35px',
  COMMA_SPACE: ', ',
  ERROR_CODE_DUPLICATE: 409,
  EXPANDED_HEIGHT: '35px',
  KEY_CLOSED: 'closed',
  KEY_CREATION_DATE: 'creationDate',
  KEY_CREATION_DATE_VIEW: 'creationDateView',
  KEY_DESCRIPRION: 'description',
  KEY_IDENTIFIER: 'identifier',
  KEY_SHIPPER_OID: 'shipperOid',
  KEY_TYPE: 'type',
  KEY_TYPE_VIEW: 'typeView',
  KEY_UPDATED_DATE: 'updatedDate',
  KEY_UPDATED_DATE_VIEW: 'updatedDateView',
  PAGE_SISE_OPTIONS: [25, 50, 100],
  PAGE_SIZE: 25,
  SYSADMIN: 'Sysadmin',
  TIMEOUT: 105
};
