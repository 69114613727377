import { Injectable } from '@angular/core';
import { FileValidationResponse } from '../../interfaces/file-response';
import { ExcelFileConfiguration } from '../../interfaces/excel-file-configuration';
import * as moment from 'moment';
@Injectable()
export class ExcelValidationService {

  private columnsKeys: Array<string>;
  private errors: Array<string>;
  private response: FileValidationResponse;
  private ordersId: Array<string>;

  public validateFile (fileConf: Array<ExcelFileConfiguration>, workingSheet: Array<any>, isValidateOrder = false,
    isTouristRequest?: boolean, isMassiveMovement?: boolean, isProductLoad?: boolean): FileValidationResponse {
    this.errors = [];
    this.response = {'validFile': true};
    this.ordersId = [];
    const equalToEigth = 8;
    const lessTwo = 2;
    this.getColumnsKeys(fileConf);
    if (this.validateColumns(workingSheet[0])) {
      for (let i = 1; i < workingSheet.length; i++) {
        for (let j = 0; j < fileConf.length; j++) {
          if (isProductLoad && !workingSheet[i][1] && !this.checkCell(fileConf[j], workingSheet[i][j], i)) {
            this.response.validFile = false;
          } else if (!this.checkCell(fileConf[j], workingSheet[i][j], i)) {
            this.response.validFile = false;
          }
          if (j === 0 && isValidateOrder) {
            this.ordersId.push(workingSheet[i][j]);
          }
          if (!isTouristRequest && j === equalToEigth && !this.validateNumberConjunction(workingSheet[i][j], workingSheet[i][j - 1],
              workingSheet[i][j - lessTwo]) && !isMassiveMovement && !workingSheet[i][1] && fileConf[j].type === 'number' && !isProductLoad) {
            this.response.validFile = false;
            this.setError('conjunction', i);
          }
        }
      }
      if (isValidateOrder) {
        this.validateUniqueStrings(this.ordersId);
      }
    } else {
      this.response.validFile = false;
      this.response.error = this.errors;
      return this.response;
    }
    this.response.error = this.errors;
    return this.response;

  }

  private getColumnsKeys (fileConf: Array<ExcelFileConfiguration>) {
    this.columnsKeys = [];
    fileConf.forEach(conf => {
      this.columnsKeys.push(conf.key);
    });
  }

  private validateColumns (workingRow: Array<string>): boolean {
    let columnsMatch: boolean;
    if (workingRow.length !== this.columnsKeys.length) {
      this.setError('columns');
      return false;
    }
    for (let i = 0; i < this.columnsKeys.length; i++) {
      if (this.columnsKeys[i].indexOf(workingRow[i]) >= 0) {
        columnsMatch = true;
      } else {
        columnsMatch = false;
        this.setError('columns');
        break;
      }
    }
    return columnsMatch;
  }

  private checkCell (columnConf: ExcelFileConfiguration, workingCell: string | number | undefined, cellRow: number): boolean {
    if ((typeof workingCell === 'undefined' || workingCell === '' || workingCell === null) && columnConf.required) {
      this.setError('required', cellRow, columnConf.key);
      return false;
    } else if ((typeof workingCell === 'undefined' || workingCell === '' || workingCell === null) && !columnConf.required) {
      return true;
    } else if (columnConf.type === 'number' || columnConf.type === 'numbers') {
      const minQuantity = columnConf.required ? 0.0000001 : 0;
      if (!this.validatePositiveNumber(workingCell, columnConf.maxQuantity, minQuantity)) {
        columnConf.type === 'numbers' ? this.setError('numbers', cellRow, columnConf.key) :
        this.setError('number', cellRow, columnConf.key);
        return false;
      }
    } else if (columnConf.type === 'coord') {
      const minQuantity = columnConf.required ? 0.0000001 : -9999999;
      if (!this.validatecoordsNumber(workingCell, columnConf.maxQuantity, minQuantity)) {
        this.setError('numbers', cellRow, columnConf.key);
        return false;
      }
    } else if (columnConf.type === 'string') {
      if (this.validateString(workingCell, columnConf.maxLength, columnConf.minLength)) {
        if (columnConf.date && !this.validateDate(workingCell)) {
          this.setError('date', cellRow, columnConf.key);
          return false;
        } else if (columnConf.hour && !this.validateHour(workingCell)) {
          this.setError('hour', cellRow, columnConf.key);
          return false;
        } else if (columnConf.enum && !this.validateEnum(columnConf.enum, workingCell)) {
          this.setError('enum', cellRow, columnConf.key);
          return false;
        }
        return true;
      } else if (columnConf.maxLength && columnConf.minLength) {
        this.setError('string', cellRow, columnConf.key, null, columnConf.maxLength, columnConf.minLength);
        return false;
      }
    }
    return true;
  }

  private setError (errorType: string, rowNumber?: number, columnName?: string, notes?: string,
    maxLength?: number, minLength?: number): void {
    rowNumber = rowNumber + 1;
    switch (errorType) {
      case 'columns':
        this.errors.push('Las columnas del archivo no coinciden');
        break;
      case 'required':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName}: el campo es requerido\n`);
        break;
      case 'type':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName}: el tipo de dato es incorrecto\n`);
        break;
      case 'number':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName}: el número es incorrecto\n`);
        break;
      case 'numbers':
        this.errors.push(`Fila: ${rowNumber}, el valor del campo ${columnName} no es numérico\n`);
        break;
      case 'string':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName}: el campo debe tener entre ${minLength} y ${maxLength} caracteres\n`);
        break;
      case 'date':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName}: la fecha es incorrecta\n`);
        break;
      case 'hour':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName}: la hora es incorrecta\n`);
        break;
      case 'conjunction':
        this.errors.push(`Fila: ${rowNumber}: al menos uno de cajas, piezas o tarimas debe ser mayor a 0\n`);
        break;
      case 'orderId':
        this.errors.push(`Filas: ${notes}: los identificadores de órdenes deben ser únicos\n`);
        break;
      case 'enum':
        this.errors.push(`Fila: ${rowNumber}, columna: ${columnName} No corresponde a los valores permitidos\n`);
        break;
      default:
        this.errors.push('Ocurrió un error al procesar la información\n');
        break;
    }

  }

  private validatePositiveNumber (num: number | string, maxValue = 99999999999999, minValue = 0): boolean {
    if (typeof num === 'string') {
      num = parseFloat(num);
      if (isNaN(num)) {
        return false;
      }
    }
    if (num >= minValue && num <= maxValue) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description validates if a coordinate is type number and accept negatives numbers
   * @param {number} num number received to be validated
   * @param  {number} maxValue max value can get the number
   * @param {number} minValue min value can get the number
   * @returns {boolean} if the coordinate is valid or not
   */
  private validatecoordsNumber (num: number | string, maxValue = 99999999999999, minValue = 9999999999): boolean {
    if (num >= minValue && num <= maxValue) {
      return true;
    } else {
      return false;
    }
  }

  private validateString (str: string | number, maxLength: number, minLength: number): boolean {
    if (typeof str === 'number') {
      str = str.toString();
    }
    if (str.length <= maxLength && str.length >= minLength) {
      return true;
    } else {
      return false;
    }
  }

  public validateDate (date: string | number, format = 'yyyy/mm/dd', beforeTodayAvailable = false): boolean {
    if (typeof date === 'number') {
      return false;
    }
    if (!this.validateDateFormat(date, format)) {
      return false;
    }
    if (!beforeTodayAvailable) {
      const dateSplitted = date.split('/');
      const dateFormated = new Date(parseInt(dateSplitted[0], 10), parseInt(dateSplitted[1], 10) - 1, parseInt(dateSplitted[2], 10));
      const startOfDay = moment().startOf('day');
      const dateToday = new Date(startOfDay.toString());

      if (dateFormated.getTime() < dateToday.getTime()) {
        return false;
      }
    }
    return true;
  }

  public validateDateFormat (date: string, formatGiven: string, format = 'yyyy/mm/dd'): boolean {
    const dateGiven = date.split('/');
    const keysGiven = formatGiven.split('/');
    const keysFormat = format.split('/');
    let validFormat = true;

    for (let i = 0; i < keysFormat.length; i++) {
      if (keysFormat[i].length !== keysGiven[i].length || keysFormat[i].length !== dateGiven[i].length) {
        validFormat = false;
      }
    }
    return validFormat;
  }

  public validateHour (time: string | number): boolean {
    if (typeof time === 'number') {
      return false;
    }
    const re = /^(0?[0-9]|1[0-9]|2[0-3])(:([0-5][0-9])){1,2}$/;
    return re.test(time);
  }

  private validateNumberConjunction (...numbers: Array<number>): boolean {
    let greaterThanZero = false;
    if (numbers.length  === 1 && numbers[0] > 0 ) {
      return true;
    }
    numbers.forEach(numToCheck => {
      if (numToCheck > 0) {
        greaterThanZero =  true;
      }
    });

    return greaterThanZero;
  }

  public validateUniqueStrings (strToCheck: Array<string>): boolean {
    let uniqueValues = true;
    for (let i = 0; i < strToCheck.length; i++) {
      for (let j = 0; j < strToCheck.length; j++) {
        if (i < j && strToCheck[i] === strToCheck[j]) {
          uniqueValues = false;
          this.setError('orderId', undefined, undefined, `${i + 1}, ${j + 1}`);
        }
      }
    }
    return uniqueValues;
  }

  /**
   * @description Validate if an enum type exist into enum collection
   * @param {object} enumCollection Enum collection
   * @param {string | number} enumType Enum type to be validated into enum collection
   * @returns {boolean} enum type validation
   */
  public validateEnum(enumCollection: object, enumType: string | number): boolean {
    let isValid: boolean;
    if ((<any>Object).values(enumCollection).includes(enumType)) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  }

}
