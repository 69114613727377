import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { ApportionmentResponse } from '../../interfaces/apportionment';
import { environment } from '../../../environments/environment';
import { Charge,
  CostStatus,
  CostUpdate,
  FreightChargeResponse,
  FreightChargeShipment,
  ShipmentCostCreate,
  ShipmentRequest,
  ShipmentUpdateStatus
} from '../../interfaces/';
import { UserService } from '../user/user.service';

const apiUrl = environment.apiUrl;
const freightCostApiUrl = environment.freightRateApiUrl;
const tmsFreightCostApiUrl = environment.tmsFreightCostApiUrl;

@Injectable()
export class CostReviewProvider {

  constructor(
    private http: HttpClient,
    private _appService: AppService,
    private userProvider: UserService
    ) {}

  public async getShipmentCostByTenant(tenantId: string, shipmentId: string): Promise<Object> {
    return await this.http.get<Object>(`${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}`).toPromise();
  }

  /**
   * @description Gets shipment request data by request Id
   * @param {string} shipmentId - The shipment request id
   * @returns {Array<ShipmentRequest>} Response with shipment request data
   */
  public async getShipmentById(shipmentId: string): Promise<Array<ShipmentRequest>> {
    const shipperOid = this._appService.getShipperOid();
    const userOid = this._appService.getUserOid();
    const user = await this.userProvider.getUserById(userOid);
    const carrier = user.lineaTransporte?._id;
    let url = `${apiUrl}/solicitudes/${shipmentId}/evidencias?embarcador=${shipperOid}`;
    if (carrier) {
      url += `&lineaTransporte=${carrier}`;
    }
    return this.http.get<Array<ShipmentRequest>>(url).toPromise();
  }

  public async getChargesTypesByTenant(tenantId: string): Promise<Object> {
    return await this.http.get<Object>(`${freightCostApiUrl}/charge/tenant/${tenantId}`).toPromise();
  }

  public async updateShipmentCost(tenantId: string, shipmentId: string, bodyCost: CostUpdate): Promise<Object> {
    return await this.http.put<Object>(`${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}`, bodyCost).toPromise();
  }

  public async createShipmentCost(tenantId: string, shipmentId: string, isFirst: boolean, bodyCost: ShipmentCostCreate): Promise<Object> {
    return await this.http.post<Object>(
      `${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}?isFirst=${isFirst}`,
      bodyCost).toPromise();
  }

  /**
   * @description Generate the charge to shipment confirmed
   * @param {string} shipperId Shipper/Tenant charges owner
   * @param {string} shipmentId shipmentId to apply charge
   * @returns {Promise<FreightChargeResponse>} Response of Charge
   */
  public async createFreightCharge(shipperId: string, shipmentId: string, bodyCost: FreightChargeShipment): Promise<FreightChargeResponse> {
    return await this.http.post<FreightChargeResponse>(
      `${freightCostApiUrl}/freightCharge/shipperId/${shipperId}/shipmentId/${shipmentId}`, bodyCost).toPromise();
  }

  /**
   * @description Find the charge to shipment beforte confirmed
   * @param {string} shipperId Shipper/Tenant charges owner
   * @param {string} shipmentId shipmentId to apply charge
   * @returns {Promise<FreightChargeResponse>} Response of Charge
   */
  public async findFreightCharge(shipperId: string, bodyCost: FreightChargeShipment): Promise<FreightChargeResponse> {
    return await this.http.post<FreightChargeResponse>(
      `${freightCostApiUrl}/shipperId/${shipperId}/freightTariffs`, bodyCost).toPromise();
  }

  public async updateCostStatus(tenantId: string, shipmentId: string, bodyCost: CostStatus): Promise<Object> {
    return await this.http.patch<Object>(
      `${freightCostApiUrl}/cost/tenant/${tenantId}/shipmentId/${shipmentId}/status`,
      bodyCost).toPromise();
  }

  public async updateShipmentStatus(shipmentOid: string, bodyShipment: ShipmentUpdateStatus): Promise<Object> {
    const userOid = this._appService.getUserOid();
    bodyShipment.user_id = userOid;
    return await this.http.put<Object>(`${apiUrl}/solicitudes/${shipmentOid}/estatus`, bodyShipment).toPromise();
  }

  /**
   * @description Get Charges catalog for the current shipper/tenant
   * @param {string} tenantId Shipper/Tenant charges owner
   * @returns {Promise<Array<Charge>>} Charges catalog
   */
  public async getChargesTypes(tenantId: string): Promise<Array<Charge>> {
    return await this.http.get<Array<Charge>>(`${freightCostApiUrl}/charge/tenant/${tenantId}`).toPromise();
  }

  /**
   * @description Generate the request to create a new apportionment
   * @param {string} tenantId Shipper/Tenant charges owner
   * @param {string} shipmentId as id of shipment
   * @param {string} shipmentRequestId shipment request id
   * @param {string} userName name of shipment or tenant
   * @returns {Promise<ApportionmentResponse>} result
   */
  public async createApportionment(shipperId: string, shipmentId: string, shipmentRequestId: string, userName: string): Promise<ApportionmentResponse> {
    return await this.http.post<ApportionmentResponse>(
      `${tmsFreightCostApiUrl}/apportionments/shipper/${shipperId}/shipment/${shipmentId}/shipmentRequest/${shipmentRequestId}?userName=${userName}`, null
    ).toPromise();
  }

  /**
   * @description Gets apportioments by shipments ID.
   * @param {string} shipperOid - Current shipper Object ID.
   * @param {Array<string>} shipmentsId - Shipments ID to find Apportionment.
   * @returns {ApportionmentResponse} Apportionments found.
   */
  public async getApportionmentsByShipmentsId(shipperOid: string, shipmentsId: Array<string>): Promise<ApportionmentResponse> {
    const body = { shipmentsId: shipmentsId };

    return await this.http.post<ApportionmentResponse>(
      `${tmsFreightCostApiUrl}/apportionments/shipper/${shipperOid}`, body
    ).toPromise();
  }
}
